import React from 'react'

// https://developers.google.com/tag-manager/devguide#events

// replace spaces with - and make all lower case
function t (str = '') {
  return str.replace(/ /g, '-').toLowerCase()
}

/**
 * Wrapper to intercep click and push to Google Tag Manager
 *
 * usage <Tracker value={eventValue}><a href="#/experiences/intro">....</a></Tracker>
 *
 * @param {[type]} props [description]
 */
export default function EventTracker (props) {
  let o

  if (Array.isArray(props.value)) {
    o = props.value.map(item => {
      for (let p in item) {
        item[p] = t(item[p])
      }
      return item
    })
  } else if (props.value) {
    o = { event: t(props.value) }
  } else {
    console.error('Missing props.value for <EventTracker>, current props are:', props)
  }

  const click = (e) => {
    if (window.dataLayer && o) {
      window.dataLayer.push(o)
    }
  }

  return <span onClick={click}>{props.children}</span>
}
