import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import React, { Component, Fragment } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import throttle from 'frame-debounce'

import logo from '../../assets/logo-welcome.svg'
import arrow from '../../assets/icons/arrow.svg'
import toTopIcon from '../../assets/icons/to-top.svg'

import { selectLocation } from '../../selectors'
import {
  SAFE_DRIVING_ROUTE,
  SAFE_DRIVING_TITLE,
  EXPERIENCES_ROUTE,
  CREATIVE_SPACE_ROUTE,
  CREATIVE_SPACE_TITLE,
  INTRO_ROUTE,
  getTitleByid
} from '../../helpers/experiences'
import Tracker from '../gtm/event-tracker'

import Item from './item'
import Subnav from './subnav'

import {
  OTHER_EXHIBITS_LINKS,
  JOURNEY_LINKS,
  isJourneyPath,
  isOtherExhibitsPath,
  getIEVersion
} from './utils'

import { hiddenFromOutreach } from '../../helpers/outreach'

import './index.css'

class Desktop extends Component {
  constructor (props) {
    super(props)
    this.onScroll = throttle(this.onScroll.bind(this))
    this.onSubnav = this.onSubnav.bind(this)
    this.onNav = this.onNav.bind(this)
    this.initialState = {
      extraCls: '',
      subnav: false,
      selectCls: ['', ''],
      activeSubav: ''
    }
    this.state = this.initialState
  }

  componentDidMount () {
    const ie = getIEVersion()
    if (ie === 11) {
      this.setState({ extraCls: 'fixed-nav' })
    } else {
      window.addEventListener('scroll', this.onScroll)
    }
  }

  componentWillUnmount () {
    const ie = getIEVersion()
    if (ie !== 11) {
      window.removeEventListener('scroll', this.onScroll)
    }
  }

  onScroll () {
    if (!this.el) return
    let extraCls = 'relative-nav'
    if (this.el.offsetTop < window.scrollY - this.el.offsetHeight) {
      extraCls = 'fixed-nav'
    }
    this.setState({ extraCls })
  }

  onSubnav (e, route, index) {
    e.preventDefault()

    let selectCls = ['', '']
    let subnav = false

    if (!this.state.subnav && this.state.selectCls[index] === '') {
      selectCls[index] = 'selected'
      subnav = !this.state.subnav
    } else if (this.state.subnav && this.state.selectCls[index] === '') {
      selectCls[index] = 'selected'
      subnav = true
    }

    this.setState({ subnav, selectCls, activeSubnav: route })
  }

  onNav () {
    if (this.state.subnav) {
      this.setState({
        ...this.initialState,
        extraCls: this.state.extraCls
      })
    }
  }

  shouldComponentUpdate (newProps, newState) {
    if (this.props.location.pathname !== newProps.location.pathname) {
      return true
    }

    if (newState.subNav !== this.state.subNav ||
      newState.activeSubnav !== this.state.activeSubnav ||
      newState.selectCls !== this.state.selectCls ||
      newState.extraCls !== this.state.extraCls) {
      return true
    }

    return false
  }

  render () {
    const cls = `header-item link white f6 f5-l f5-m f5-ns dib mr3 tl mh4`
    const subnavCls = 'subnav right-0 absolute w-100 flex items-center justify-end pr4'
    const path = this.props.location.pathname
    let links
    let exp

    if (
      path.indexOf(EXPERIENCES_ROUTE) === -1 &&
      path.indexOf('/legal') === -1 &&
      path.indexOf('/support') === -1
    ) {
      return null
    }

    if (this.state.activeSubnav === EXPERIENCES_ROUTE) {
      links = JOURNEY_LINKS
      exp = isJourneyPath(path)
    } else {
      links = OTHER_EXHIBITS_LINKS
      exp = isOtherExhibitsPath(path)
    }

    if (this.props.outreach) {
      links = links.filter(id => !hiddenFromOutreach(id))
    }

    return (
      <Fragment>
        <div id='header-marker' ref={(e) => { this.el = e }} />
        <div className={`header-desktop-space ${this.state.extraCls}`} />
        <nav className={`header w-100 top-0 flex items-center ${this.state.extraCls} background fw4`} >
          <div className={`header w-100 pa3 pa4-ns top-0 flex items-center background`} id='header' >
            <a className='logo mw4 ml5 ml5-l ml3-m ml3-ns' href='https://www.towardszero.vic.gov.au/'>
              <Tracker value='header-rtz-logo-click'><img className='logo v-mid' src={logo} /></Tracker>
            </a>
            <div className='item-container mv2 w-90'>
              <div className='fr nav-item-container flex items-center'>
                <div className={`mh4 header-item link white f6 f5-l f5-m f5-ns dib mr3 pointer flex items-center ${this.state.selectCls[0]}`} onClick={(e) => { this.onSubnav(e, EXPERIENCES_ROUTE, 0) }}>
                  <img className={`arrow mr2 ${this.state.selectCls[0]}`} src={arrow} />
                  YOUR EXPERIENCES
                </div>

                <div className={`mh4 header-item link white f6 f5-l f5-m f5-ns dib mr3 pointer flex items-center ${this.state.selectCls[1]}`} onClick={(e) => { this.onSubnav(e, 'other', 1) }}>
                  <img className={`arrow mr2 ${this.state.selectCls[1]}`} src={arrow} />
                  OTHER EXHIBITS
                </div>

                {
                  <Item
                    to={SAFE_DRIVING_ROUTE}
                    onClick={this.onNav}
                    className={cls}
                    selected={path === SAFE_DRIVING_ROUTE}
                  >{SAFE_DRIVING_TITLE}</Item>
                }

                {
                  this.props.hasCreative &&
                  <Item
                    to={CREATIVE_SPACE_ROUTE}
                    onClick={this.onNav}
                    className={cls}
                    selected={path.indexOf(CREATIVE_SPACE_ROUTE) > -1}
                  >
                    {CREATIVE_SPACE_TITLE}
                  </Item>
                }
              </div>
            </div>
          </div>
          <ReactCSSTransitionGroup
            transitionName='slide'
            transitionEnterTimeout={200}
            transitionLeaveTimeout={200}
            transitionAppearTimeout={200}
            transitionAppear
          >
            { this.state.subnav &&
            <Subnav className={`${subnavCls}`}>
              { links.map((link, index) => {
                const title = getTitleByid(link)
                let lastItem = ''
                if (index === links.length - 1) lastItem = 'mr4'
                return <Item onClick={this.onNav} key={link} selected={exp} className={`${cls} mh3 ${lastItem}`}>{title}</Item>
              })
              }
            </Subnav>
            }
          </ReactCSSTransitionGroup>
          <ReactCSSTransitionGroup
            transitionName='fade'
            transitionEnterTimeout={400}
            transitionLeaveTimeout={400}
            transitionAppearTimeout={400}
            transitionAppear
          >
            { this.state.extraCls === 'fixed-nav' &&
            <Tracker value='return-to-top-click'>
              <Link className='to-top-icon fixed pr5 pl4 pv2 flex items-center justify-center' to={INTRO_ROUTE}>
                <img src={toTopIcon} />
              </Link>
            </Tracker>
            }
          </ReactCSSTransitionGroup>
        </nav>
      </Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({})

const mapStateToProps = (state) => ({
  location: selectLocation(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(Desktop)
