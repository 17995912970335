import React, { Component } from 'react'
import './style.css'

export default class Section extends Component {
  shouldComponentUpdate (b, a) {
    return this.props.forceUpdate || false
  }

  render () {
    const c = this.props.theme ? this.props.theme : 'white'
    const bgCircle = this.props.useCircle
      ? <span className='circle' style={this.props.styleCircle || {}} />
      : null

    let content = <h1>CONTENT</h1>
    if (this.props.children) {
      content = this.props.children
    }

    const cls = this.props.className ? this.props.className : null

    return (
      <div
        className={`flex section justify-center items-center theme-${c} ph0 ph4-l`}
        id={this.props.id}>
        {bgCircle}
        <div className={`content pv0 pt4-m pb5-m pv6-l ${cls}`}>
          {content}
        </div>
      </div>
    )
  }
}
