/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { isMobile } from './utils/device'
import { fromJS } from 'immutable'
import { INIT, LOAD_DATA_ERROR } from './actions'

// The initial state of the App
const initialState = fromJS({
  content: {},
  _csrf: '',
  authenticated: false,
  loading: true,
  posted: false,
  mobile: isMobile()
})

export default function mainReducer (state = initialState, action) {
  switch (action.type) {
    case INIT:
      const { data, csrfToken } = action.data
      return state
        .set('authenticated', true)
        .set('loading', false)
        .set('_csrf', fromJS(csrfToken))
        .set('content', fromJS(data))
    case LOAD_DATA_ERROR:
      return state
        .set('authenticated', false)
        .set('loading', false)
    default:
      return state
  }
}
