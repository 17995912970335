import React from 'react'
import { Link } from 'react-router-dom'
import Tracker from '../gtm/event-tracker'

export default function Item (props) {
  let cls = props.className
  let id = ''

  if (props.selected) cls += ' selected'
  if (props.to) id = props.to.replace(/\/experiences\//g, '')

  return (
    <Link className={`${cls}`} to={props.to} onClick={props.onClick || null}>
      <Tracker value={`menu-item-${id}-click`}>{props.children}</Tracker>
    </Link>
  )
}
