import React from 'react'
import Tracker from '../../gtm/event-tracker'

export default function SummaryItem ({ data, length }) {
  let width = 'w-third-l'
  let op = data[3] ? '' : 'o-50'
  switch (length) {
    case 1:
      width = 'w-100'
      break
    case 2:
      width = 'w-50 w-half-l'
      break
    default:
      width = 'w-50 w-third-l'
  }

  return (
    <div className={`${width} pa3`}>
      <Tracker value={`summary-${data[0]}-click`}>
        <a className='exp-link pv1 tc flex flex-column items-center' href={`#${data[2]}`}>
          <img className={`exp-icon ${op}`} src={data[1]} />
          <p className='fos fw6 exp-icon-label mt3 nowrap'>{data[0]}</p>
        </a>
      </Tracker>
    </div>
  )
}
