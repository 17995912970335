import React, { Component } from 'react'
import Section from '../../section'
import BodyBuiltIcon from '../../../assets/icons/Icon_BBTS@2x.png'
import CrashForcesIcon from '../../../assets/icons/Icon_Crash forces@2x.png'
import CrashlabIcon from '../../../assets/icons/Icon_Crashlab@2x.png'
import DoYourPartIcon from '../../../assets/icons/Icon_Do your part@2x.png'
import GlobalOutlookIcon from '../../../assets/icons/Icon_Global Outlook@2x.png'
import SaferBehavioursIcon from '../../../assets/icons/Icon_Safer Behaviours@2x.png'
import SaferSystemsIcon from '../../../assets/icons/Icon_Safer systems@2x.png'
import SpeedPhysicsIcon from '../../../assets/icons/Icon_Speed Physics@2x.png'
import VrRtzIcon from '../../../assets/icons/Icon_VR RTZ@2x.png'
import {
  BODY_BUILT_ID,
  BODY_BUILT_ROUTE,
  CRASH_FORCES_ID,
  CRASH_FORCES_ROUTE,
  CRASHLAB_ID,
  CRASHLAB_ROUTE,
  DO_YOUR_PART_ID,
  DO_YOUR_PART_ROUTE,
  GLOBAL_OUTLOOK_ID,
  GLOBAL_OUTLOOK_ROUTE,
  SAFER_BEHAVE_ID,
  SAFER_BEHAVE_ROUTE,
  SAFER_SYSTEMS_ID,
  SAFER_SYSTEMS_ROUTE,
  SPEED_PHYSICS_ID,
  SPEED_PHYSICS_ROUTE,
  VR_RTZ_ID,
  VR_RTZ_ROUTE
} from '../../../helpers/experiences'
import { hiddenFromOutreach } from '../../../helpers/outreach'
import Tracker from '../../gtm/event-tracker'
import './index.css'
import SummaryItem from './item'

const expMap = new Map()
expMap.set(BODY_BUILT_ID, ['Body Built', BodyBuiltIcon, BODY_BUILT_ROUTE])
expMap.set(CRASH_FORCES_ID, ['Crash Forces', CrashForcesIcon, CRASH_FORCES_ROUTE])
expMap.set(CRASHLAB_ID, ['Crashlab', CrashlabIcon, CRASHLAB_ROUTE])
expMap.set(DO_YOUR_PART_ID, ['Do Your Part', DoYourPartIcon, DO_YOUR_PART_ROUTE])
expMap.set(GLOBAL_OUTLOOK_ID, ['Global Outlook', GlobalOutlookIcon, GLOBAL_OUTLOOK_ROUTE])
expMap.set(SAFER_BEHAVE_ID, ['Safer Behaviours', SaferBehavioursIcon, SAFER_BEHAVE_ROUTE])
expMap.set(SAFER_SYSTEMS_ID, ['Safer Systems', SaferSystemsIcon, SAFER_SYSTEMS_ROUTE])
expMap.set(SPEED_PHYSICS_ID, ['Speed, Physics & Crashes', SpeedPhysicsIcon, SPEED_PHYSICS_ROUTE])
expMap.set(VR_RTZ_ID, ['VR Roadtrip to Zero', VrRtzIcon, VR_RTZ_ROUTE])

export default class SummaryExperience extends Component {
  hasContent (exp) {
    const content = this.props.content
    let b = false
    if (content.hasOwnProperty(exp) && content[exp]) {
      let experience = content[exp]
      let experienceTmp = experience
      if (experience) {
        let keys = Object.keys(experienceTmp)
        // check if has atleast one sub key not null
        const hasSubContent = keys.filter(k => experience[k] !== null).length > 0
        experience = hasSubContent ? experience : null
        if (experience) b = true
      }
    }
    return b
  }

  render () {
    let item
    let nExperience = 0
    const contentList = []
    const isOutreach = this.props.outreach

    let order
    if (isOutreach) {
      order = [
        BODY_BUILT_ID,
        SAFER_SYSTEMS_ID,
        CRASHLAB_ID,
        SAFER_BEHAVE_ID,
        DO_YOUR_PART_ID,
        VR_RTZ_ID,
        GLOBAL_OUTLOOK_ID
      ]
      for (let p in order) {
        let exp = order[p]
        item = expMap.get(exp)
        item[3] = true
        nExperience += 1
        const addToContentList = !hiddenFromOutreach(exp)
        if (addToContentList) contentList.push(item)
      }
    } else {
      order = [
        BODY_BUILT_ID,
        CRASH_FORCES_ID,
        SAFER_SYSTEMS_ID,
        CRASHLAB_ID,
        SAFER_BEHAVE_ID,
        DO_YOUR_PART_ID,
        SPEED_PHYSICS_ID
      ]
      for (let p in order) {
        let exp = order[p]
        item = expMap.get(exp)
        item[3] = this.hasContent(exp)
        if (item[3]) nExperience += 1
        contentList.push(item)
      }
    }

    const componentsList = contentList.map((data, k) => {
      const key = data[0].replace(' ', '-').toLowerCase()
      return <SummaryItem data={data} length={contentList.length} key={key} />
    })

    const numExp = contentList.length

    return <Section id={this.props.id} theme='blue'>
      <div className='exp-summary tc pv5'>
        <h1 className='heading1 ph4 ph0-ns lh-solid'>RELIVE YOUR JOURNEY</h1>
        { !isOutreach && <p className='subtitle fos fw6 pt3 pb3 ph4 lh-copy'>You collected {nExperience}/{numExp} experiences.</p> }
        { isOutreach && <div className='pt3' /> }
        <p className='info fos w-80 w-100-l ph4-l lh-copy dib'>Click on one of the badges below to relive your journey.</p>

        <div className='flex items-center justify-center mv4'>
          <div className='flex justify-center flex-wrap exp-icons-list mw7 ph4'>{componentsList}</div>
        </div>
        <Tracker value='summary-cta-click'>
          <a className='tbutton clear white mt3 mh4 mh0-ns flex' href={`#${BODY_BUILT_ROUTE}`}>VIEW YOUR EXPERIENCES</a>
        </Tracker>
      </div>
    </Section>
  }
}
