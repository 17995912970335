import cityImage from '../../../assets/images/safer-systems/city-cbd.jpg'
import outerSuburbanImage from '../../../assets/images/safer-systems/outer-suburban.jpg'
import ruralImage from '../../../assets/images/safer-systems/rural.jpg'
import suburbanImage from '../../../assets/images/safer-systems/suburban.jpg'

// Unique keys for app variations
const RURAL = 'rural'
const SUBURBAN = 'suburban'
const CITY_CBD = 'citycbd'
const OUTER_SUBURBAN = 'outersuburban'

let data = new Map()
data.set(RURAL, {
  title: 'HIGH SPEED COUNTRY ROADS',
  body: 'Country roads are high risk areas because of the speed at which vehicles travel. How did you go improving the safety of this part of the road environment?',
  imageUrl: ruralImage
})
data.set(SUBURBAN, {
  title: 'SUBURBAN INTERSECTIONS',
  body: 'Suburban intersections are high risk areas because of many vehicles sharing the road and traveling in different directions at high speeds. Did you make a difference?',
  imageUrl: suburbanImage
})
data.set(CITY_CBD, {
  title: 'BUSY SHOPPING AREAS',
  body: 'Busy shopping precincts are high risk areas because of the many pedestrians, cyclists and cars all sharing the roads.  Did you help reduce the risk in this area?',
  imageUrl: cityImage
})
data.set(OUTER_SUBURBAN, {
  title: 'RESIDENTIAL STREETS',
  body: 'Residential streets shared by many different types of road users are a high risk area. Did you manage to make this part of the roads network safer?',
  imageUrl: outerSuburbanImage
})

function getByType (type) {
  // remove the variation of side from the type
  const t = getTypeWithoutSide(type)
  return data.get(t)
}

/**
 * Remove east or west from the key
 *
 * @param {String} type
 */
function getTypeWithoutSide (type) {
  return type.replace(/(east|west)/gi, '')
}

/**
 * Prepare the content for the galley, converting the keys to
 * be unique and merges the user's score into it
 *
 * @param {Object} content - User score per safer systems app variation:
 *
 *                          "safersystems": {
 *                            "eastoutersuburban": 1,
 *                            "eastrural": 1,
 *                            "eastsuburban": null,
 *                            "eastcitycbd": 1,
 *                            "westcitycbd": 0,
 *                            "westoutersuburban": 2,
 *                            "westrural": 0,
 *                            "westsuburban": 3
 *                          }
 */

function prepare (content, outreach) {
  const items = []
  const uniqueTypes = new Map()
  const scoreReducer = (acc, value) => acc + value

  // get unique keys and save the highest score for each one
  if (outreach) {
    data.set(OUTER_SUBURBAN, {
      title: 'LOCAL STREETS',
      body: 'Local streets shared by many different types of road users are a high risk area. Did you manage to make this part of the roads network safer?',
      imageUrl: outerSuburbanImage
    })

    data.set(SUBURBAN, {
      title: 'TOWN CENTRE',
      body: 'Town centres are high risk areas because of many vehicles sharing the road and traveling in different directions at high speeds. Did you make a difference?',
      imageUrl: suburbanImage
    })
  }
  for (let p in content) {
    if (content.hasOwnProperty(p)) {
      const key = getTypeWithoutSide(p)
      let objScore = content[p] || { score: [0, 0, 0] }
      let arrScore = objScore.score

      for (var k in arrScore) {
        if (!arrScore[k]) {
          arrScore[k] = 0
        }
      }
      // check if the key exists or
      // if the current score is lower than the new Score
      if (!uniqueTypes.has(key)) {
        // console.log(key, p, 'add new score', arrScore)
        uniqueTypes.set(key, arrScore)
      } else {
        const newScore = arrScore.reduce(scoreReducer)
        const oldScore = uniqueTypes.get(key).reduce(scoreReducer)
        // console.log(key, p, 'compare scores: newScore', newScore, 'oldScore', oldScore)

        if (newScore > oldScore) {
          // add higher score array
          uniqueTypes.set(key, arrScore)
        }
      }
    }
  }

  // create and object with the data and also add user's score
  uniqueTypes.forEach((score, key) => {
    const o = Object.assign({ score }, getByType(key))
    if (!(o.title === 'BUSY SHOPPING AREAS' && outreach)) {
      items.push(o)
    }
  })

  return items
}

export default {
  prepare
}
