import React from 'react'
import CarsImage from '../../../../../assets/images/create-space/science.wet-and-dry.cars.png'
import CarDryImage from '../../../../../assets/images/create-space/science.wet-and-dry.car.dry.png'
import CarWetImage from '../../../../../assets/images/create-space/science.wet-and-dry.car.wet.png'
import DotDryImage from '../../../../../assets/images/create-space/science.wet-and-dry.dot.dry.png'
import DotWetImage from '../../../../../assets/images/create-space/science.wet-and-dry.dot.wet.png'
import './index.css'

export default ({ user, data }) => {
  const speeds = parseData(data, user._id)
  const distances = [...Array(19)]

  return <div className='wet-and-dry tl tc-ns pt5 pb4 pb5-m pb3-l'>
    <h1 className='heading1 lh-solid ph4 ph2-m ph0-ns'>WET &amp; DRY VIRTUAL STOPPING DISTANCE</h1>
    <p className='info fos f6 f5-ns lh-copy mt4 mb5 ph4 ph6-ns'>The graph below represents your stopping distance prediction for each run. Compare your predictions against the real-world stopping ranges.</p>
    <div className='fos f7 ph3 ph4-ns'>
      <div className='ranges-wrapper flex flex-column'>
        <div className='ranges'>
          { speeds.map(({ speed, dry, wet }) => {
            return <div key={speed} className='speed relative flex items-center' style={{ height: '80px' }}>
              { distances.map((_, j) => <span key={j} className='distance-line relative db flex-auto h-100' />) }
              <div className='speed-info absolute flex flex-column items-center'>
                <img src={CarsImage} className='cars' />
                <div className='mt1'>{ speed }KM/H</div>
              </div>
              <div className='absolute w-100'>
                { dry &&
                  <div className='relative bg-white' style={{ height: '13px' }}>
                    <div className='dry absolute h-100' style={{ left: dry.bar.left, width: dry.bar.width }} />
                    <div className='absolute fw6 nowrap' style={{ left: dry.text.left, top: '-18px', transform: 'translateX(-50%)' }}>
                      { dry.min }M to { dry.max}M
                    </div>
                    <img src={DotDryImage} className='absolute dot' style={{ left: dry.dot.left, transform: 'translateX(-6px)' }} />
                  </div>
                }
                { wet &&
                  <div className='relative bg-white' style={{ height: '13px' }}>
                    <div className='wet absolute h-100' style={{ left: wet.bar.left, width: wet.bar.width }} />
                    <div className='absolute fw6 nowrap' style={{ left: wet.text.left, bottom: '-18px', transform: 'translateX(-50%)' }}>
                      { wet.min }M to { wet.max}M
                    </div>
                    <img src={DotWetImage} className='absolute dot' style={{ left: wet.dot.left, transform: 'translateX(-6px)' }} />
                  </div>
                }
              </div>
            </div>
          }) }
        </div>
        <div className='distance-axis dn flex-l items-center' style={{ height: '60px' }}>
          { distances.map((_, i) => <DistanceAxisItem key={i} index={i} />) }
        </div>
        <div className='legend flex flex-wrap justify-left justify-around-l'>
          <div className='w-100 w-auto-l flex flex-wrap items-center mt3 mb2 mv0-l'>
            <div className='w-100 w-auto-l pr0 pr3-l h-auto flex items-center lh-title mb2 mb0-l'>
              REAL TIME STOPPING RANGE:
            </div>
            <div className='w-100 w-auto-l flex items-center h2'>
              <img src={CarDryImage} className='car pr3 pr2-l' /> DRY
              <img src={CarWetImage} className='car pl4 pr3 ph2-l' /> WET
            </div>
          </div>
          <div className='w-100 w-auto-l flex flex-wrap items-center'>
            <div className='w-100 w-auto-l pr0 pr3-l h-auto flex items-center lh-title'>
              YOUR <span className='dn di-ns'>&nbsp;STOPPING&nbsp;</span> DISTANCE PREDICTION:
            </div>
            <div className='w-100 w-auto-l flex items-center h2'>
              <img src={DotDryImage} className='dot pr2 pt1' /> DRY
              <img src={DotWetImage} className='dot pl5 pr2 ph2-l pt1' /> WET
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

const DistanceAxisItem = ({ index }) => {
  const appendage = index === 18
    ? <span className='absolute w-100 h-100' style={{ right: 0, transform: 'translateX(50%)', lineHeight: 0 }}>190M</span>
    : ''

  return <div className='relative flex-auto'>
    <span className='absolute w-100 h-100' style={{ left: 0, transform: 'translateX(-50%)', lineHeight: 0 }}>{ index * 10 }M</span>
    { appendage }
  </div>
}

const parseData = (source, userId) => {
  const reduced = source.reduce((carry, run) => {
    if (!run) return carry
    const { speed, condition, range, predictions } = run
    const prediction = predictions[userId] || Object.values(predictions)[0]
    if (!prediction) return carry
    let index = carry.findIndex(_ => _.speed === speed)
    if (index === -1) {
      carry.push({ speed })
      index = carry.length - 1
    }
    carry[index][condition] = {
      ...range,
      dot: prediction
    }
    return carry
  }, [])

  const mapped = reduced.map(({ speed, dry, wet }) => {
    const o = { speed }
    if (dry) {
      o.dry = {
        ...dry,
        bar: { left: `${(dry.min / 190) * 100}%`, width: `${((dry.max - dry.min) / 190) * 100}%` },
        text: { left: `${((dry.min + (dry.max - dry.min) / 2) / 190) * 100}%` },
        dot: { left: `${(dry.dot / 190) * 100}%` }
      }
    }
    if (wet) {
      o.wet = {
        ...wet,
        bar: { left: `${(wet.min / 190) * 100}%`, width: `${((wet.max - wet.min) / 190) * 100}%` },
        text: { left: `${((wet.min + (wet.max - wet.min) / 2) / 190) * 100}%` },
        dot: { left: `${(wet.dot / 190) * 100}%` }
      }
    }
    return o
  })

  const sorted = mapped.slice()
    .sort((a, b) => a.speed - b.speed)

  return sorted
}
