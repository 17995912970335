import React, { Component } from 'react'
import Section from '../../section'
import ImageInfoPanel from '../panels/image-info'
import CarRed from '../../../assets/images/crashlab/red.jpg'
import CarBlue from '../../../assets/images/crashlab/blue.jpg'
import CarGreen from '../../../assets/images/crashlab/green.jpg'
import CarOrange from '../../../assets/images/crashlab/orange.jpg'
import CarYellow from '../../../assets/images/crashlab/yellow.jpg'

export default class CrashlabExperience extends Component {
  render () {
    const cta = this.getCta()
    const carImageUrl = this.getCarImageUrl()
    return <Section id={this.props.id} theme='white'>
      <ImageInfoPanel
        title='CRASHLAB'
        link={cta.url}
        linkLabel={cta.label}
        imageUrl={carImageUrl}
        imageSide='right'
        noLightbox
      >
        <p>Car safety features are constantly improving. They work together to help you avoid collisions, and protect you if a crash does occur.</p>
      </ImageInfoPanel>
    </Section>
  }

  getCta () {
    return this.props.links && this.props.links.cta
      ? this.props.links.cta
      : { label: 'READ MORE', url: 'http://www.howsafeisyourcar.com.au/Safety-Features/' }
  }

  getCarImageUrl () {
    const defaultImage = CarBlue
    return this.props.content && this.props.content.color
      ? this.getCarImageUrls()[this.props.content.color] || defaultImage
      : defaultImage
  }

  getCarImageUrls () {
    return {
      red: CarRed,
      blue: CarBlue,
      green: CarGreen,
      orange: CarOrange,
      yellow: CarYellow
    }
  }
}
