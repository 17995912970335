import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import arrow from '../../assets/icons/arrow.svg'
import burger from '../../assets/icons/burger.svg'
import close from '../../assets/icons/close.svg'

import { selectLocation } from '../../selectors'
import {
  EXPERIENCES_ROUTE,
  SAFE_DRIVING_ROUTE,
  SAFE_DRIVING_TITLE,
  CREATIVE_SPACE_ROUTE,
  CREATIVE_SPACE_TITLE,
  getTitleByid
} from '../../helpers/experiences'

import Item from './item'
import Subnav from './subnav'
import {
  OTHER_EXHIBITS_LINKS,
  JOURNEY_LINKS,
  isJourneyPath,
  isOtherExhibitsPath
} from './utils'

import { hiddenFromOutreach } from '../../helpers/outreach'

import './index.css'

class Mobile extends Component {
  constructor (props) {
    super(props)
    this.onSubnav = this.onSubnav.bind(this)
    this.onNav = this.onNav.bind(this)
    this.initialState = {
      subnav: [false, false],
      selectCls: ['', ''],
      showNav: false,
      activeSubnav: ''
    }
    this.state = this.initialState
  }

  onNav () {
    if (!this.state.showNav) {
      this.setState({ showNav: true })
    } else {
      this.setState(this.initialState)
    }
  }

  onSubnav (e, route, index) {
    e.preventDefault()

    let selectCls = ['', '']
    let subnav = [false, false]
    if (!this.state.subnav && this.state.selectCls[index] === '') {
      selectCls[index] = 'selected'
      subnav[index] = !this.state.subnav
    } else if (this.state.subnav && this.state.selectCls[index] === '') {
      selectCls[index] = 'selected'
      subnav[index] = true
    }

    this.setState({
      subnav: subnav,
      selectCls,
      activeSubnav: route
    })
  }

  render () {
    const cls = `header-item link white f6 f5-l f5-m f5-ns dib mr3 tl`
    const subnavCls = 'subnav-mobile left-0 absolute w-100 flex'
    const path = this.props.location.pathname
    let links
    let exp

    if (
      path.indexOf(EXPERIENCES_ROUTE) === -1 &&
      path.indexOf('/legal') === -1 &&
      path.indexOf('/support') === -1
    ) {
      return null
    }

    if (this.state.activeSubnav === EXPERIENCES_ROUTE) {
      links = JOURNEY_LINKS
      exp = isJourneyPath(path)
    } else {
      links = OTHER_EXHIBITS_LINKS
      exp = isOtherExhibitsPath(path)
    }

    if (this.props.outreach) {
      links = links.filter(id => !hiddenFromOutreach(id))
    }

    return (
      <Fragment>
        <nav className={`header mobile w-100 pa3 pr0 top-0 flex items-center fixed-nav background`} id='header'>
          <div className='w-100'>
            { !this.state.showNav &&
              <img className='nav-icon fr pointer' src={burger} onClick={this.onNav} />
            }

            { this.state.showNav &&
              <img className='nav-icon fr pointer' src={close} onClick={this.onNav} />
            }

          </div>

          { this.state.showNav &&
            <div className='header-drop-down-container absolute min-vh-100 left-0 h-100 w-100 fw4'>
              <div className='header-drop-down min-vh-100 pt4 pt4-ns pa5 nl3'>
                <h1 className='f2 white ml1'>MENU</h1>
                <div className='item-container mt2 w-90'>
                  <div className='nav-item-container flex-column'>
                    <div className={`mh1 relative header-item link white f6 f5-l f5-m f5-ns dib mr3 pointer flex items-center ${this.state.selectCls[0]} mv3 mv4-m`} onClick={(e) => { this.onSubnav(e, EXPERIENCES_ROUTE, 0) }}>
                      <img className={`arrow absolute left-0 ${this.state.selectCls[0]}`} src={arrow} />
                      YOUR EXPERIENCES
                    </div>
                    <ReactCSSTransitionGroup
                      transitionName='sub-slide'
                      transitionEnterTimeout={200}
                      transitionLeaveTimeout={200}
                      transitionAppearTimeout={200}
                      transitionAppear
                    >
                      { this.state.subnav[0] &&
                        <Subnav className={subnavCls}>
                          { links.map((link, index) => {
                            const title = getTitleByid(link)
                            let frstItem = ''
                            if (index === 0) frstItem = 'mv0 mv0-m'
                            return <Item onClick={this.onNav} key={link} selected={exp} className={`${cls} mh1 mh1-ns mh1-m ${frstItem} mt3 mt4-m`}>{title}</Item>
                          })
                          }
                        </Subnav>
                      }

                      <div className={`mh1 relative header-item link white f6 f5-l f5-m f5-ns dib mr3 pointer flex items-center ${this.state.selectCls[1]} mv3 mv4-m`} onClick={(e) => { this.onSubnav(e, 'other', 1) }}>
                        <img className={`arrow absolute left-0 ${this.state.selectCls[1]}`} src={arrow} />
                        OTHER EXHIBITS
                      </div>
                      { this.state.subnav[1] &&
                        <Subnav className={subnavCls}>
                          { links.map((link, index) => {
                            const title = getTitleByid(link)
                            let frstItem = ''
                            if (index === 0) frstItem = 'mv0 mv0-m'
                            return <Item onClick={this.onNav} key={link} selected={exp} className={`${cls} mh1 mh1-ns mh1-m ${frstItem} mt3 mt4-m`}>{title}</Item>
                          })
                          }
                        </Subnav>
                      }

                      {
                        <Item
                          to={SAFE_DRIVING_ROUTE}
                          onClick={this.onNav}
                          className={`${cls} flex mh1 mv3 mv4-m`}
                          selected={path === SAFE_DRIVING_ROUTE}
                        >{SAFE_DRIVING_TITLE}</Item>
                      }

                      {
                        this.props.hasCreative &&
                        <Item
                          to={CREATIVE_SPACE_ROUTE}
                          onClick={this.onNav}
                          className={`${cls} flex mh1 mv3 mv4-m`}
                          selected={path.indexOf(CREATIVE_SPACE_ROUTE) > -1}
                        >{CREATIVE_SPACE_TITLE}</Item>
                      }
                    </ReactCSSTransitionGroup>
                  </div>
                </div>
              </div>
            </div>
          }
        </nav>
      </Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({})

const mapStateToProps = (state) => ({
  location: selectLocation(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(Mobile)
