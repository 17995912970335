import React, { Component, Fragment } from 'react'
import Health from './health'
import Physics from './physics'
import Science from './science'
import { isRevoked } from '../../../helpers/revoked'

export default class CreativeSpaceExperience extends Component {
  render () {
    const { id, openMedia, user, links } = this.props
    const { health, physics, science } = this.props.content
    return <Fragment>
      { health && !isRevoked(health.url) && <Health {...{ id, openMedia }} data={health} /> }
      { physics && <Physics {...{ id, openMedia }} data={physics} /> }
      { science && <Science {...{ id, user, links }} data={science} /> }
    </Fragment>
  }
}
