import React from 'react'
// import previewIcon from '../../../assets/icons/preview.svg'

export default function GalleryItem (props) {
  const { title, copy, image, video } = props.content

  const openVideo = () => {
    const videoEl = <video
      width='692'
      height='460'
      controls='true'
      src={video}
      className='image pa0'
      autoPlay='true'
    />
    props.openMedia(videoEl, { width: 692, height: 460 }, true)
  }

  let time = Date.now()
  let x = 0
  let y = 0

  const onTouchStart = (event) => {
    const e = event.touches ? event.touches[0] : event
    time = Date.now()
    x = e.clientX
    y = e.clientY
  }

  const onTouchEnd = (event) => {
    const e = event.changedTouches ? event.changedTouches[0] : event
    const n = Date.now()
    const d = Math.abs(y - e.clientY + x - e.clientX)

    if (n - time < 300 && d < 20) {
      openVideo()
      event.preventDefault()
    }
  }

  // const extraCls = mobile ? 'show-icon' : ''
  // const extraCls = 'show-icon'

  return (

    <div className='gallery-item flex flex-column flex-row-l w-100 justify-center'>
      <div className='gallery-item-image'>
        <img src={image}
          onMouseDown={(e) => onTouchStart(e)}
          onMouseUp={(e) => onTouchEnd(e)}
          onTouchStart={(e) => onTouchStart(e)}
          onTouchEnd={(e) => onTouchEnd(e)}
        />
      </div>
      <div className='gallery-item-info tl ph4'>
        <p className='heading2 f1-ns title pt4 pt0-l'>{title}</p>
        <p className='fos lh-copy pt4'>{copy}</p>
      </div>
    </div>
  )
}

/*
<div className={`relative media-container`}>
    <img src={image}
      onMouseDown={(e) => onTouchStart(e)}
      onMouseUp={(e) => onTouchEnd(e)}
      onTouchStart={(e) => onTouchStart(e)}
      onTouchEnd={(e) => onTouchEnd(e)}
    />
    <div
      onClick={() => this.openMedia()}
      className={`media-overlay absolute flex justify-center items-center flex-column pointer ${extraCls}`}
    >
      <img className='mt4' src={previewIcon} />
      <h5 className='white mt3'>PREVIEW</h5>
    </div>
  </div>
</div>
 */
