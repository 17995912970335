import React, { Component } from 'react'
import ImageInfoPanel from '../panels/image-info'
import Placeholder from '../../../assets/placeholders/meet-graham.jpg'
import Section from '../../section'

export default class MeetGrahamExperience extends Component {
  render () {
    const { cta } = this.props.links
    return <Section id={this.props.id} theme='white'>
      <ImageInfoPanel
        title='MEET GRAHAM'
        link={cta.url}
        linkLabel={cta.label}
        imageUrl={Placeholder}
        noLightbox
      >
        <p>Because the human body is not designed to survive collisions at high speeds,
          we designed Graham. Explore some of the features that help protect Graham
          from the dangers of the road.</p>
      </ImageInfoPanel>
    </Section>
  }
}
