import React, { Component, Fragment } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Home from '../home'
import Welcome from '../welcome'
import Experiences from '../experiences'
import Error from '../error'
import {
  SAFER_SYSTEMS_ROUTE,
  SAFER_SYSTEMS_LEGACY_ROUTE
} from './../../helpers/experiences'
import './tachyons.min.css'
import './style.css'

export default class App extends Component {
  render () {
    return (
      <Fragment>
        <Switch>
          <Route path='/welcome/:id?' component={Welcome} />
          <Redirect from={SAFER_SYSTEMS_LEGACY_ROUTE} to={SAFER_SYSTEMS_ROUTE} />
          <Route path='/experiences' component={Experiences} />
          <Route exact path='/' component={Home} />
          <Route exact path='/error' component={Error} />
          <Route path='*' component={Error} />
        </Switch>
      </Fragment>
    )
  }
}
