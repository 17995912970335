import React from 'react'
import ImageInfoPanel from '../../../panels/image-info'
import SpeedAndFrictionImage from '../../../../../assets/images/create-space/science.speed-and-friction.jpg'

export default _ => {
  const what = `<span>
    <span class='dib dn-ns'>SPEED &amp; FRICTION</span>
    <span class='dn dib-ns'>SPEED&nbsp;&amp;&nbspFRICTION</span>
  </span>`

  return <ImageInfoPanel
    htmlTitle={`EFFECT OF ${what}`}
    imageUrl={SpeedAndFrictionImage}
    imageSide='right'
    theme='blue'
    noLightbox
    infoPadding='ph4 ph2-m ph0-ns'
  >
    <div>
      <p>You've learnt that increasing a vehicle's speed increases its stopping distance, making higher speeds more dangerous and a higher risk of injuries and fatalities. Find out more about the effect of Speed and Friction to safety by clicking on the button below.</p>
    </div>
  </ImageInfoPanel>
}
