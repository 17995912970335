import React, { Component } from 'react'
import Section from '../../section'
import Logo from '../../../assets/logo-intro.svg'
import './index.css'

export default class IntroExperience extends Component {
  render () {
    const styleCircle = this.props.mobile
      ? { top: '-25vw', left: '-52.5vw', borderWidth: '10vw' }
      : null

    return <Section id={this.props.id} theme='blue' useCircle styleCircle={styleCircle}>
      <div className='exp-intro tc pt6 pb5 pt5-l pb5-l'>
        <img src={Logo} className='mt5 mt0-l' />
        <h1 className='heading1 ph4 mt5 lh-solid'>EXPERIENCE SPACE</h1>
        <p className='info fos w-80 w-100-l ph4-l mt4 mt5-l dib lh-copy f6 f5-l'>You've completed the Road to Zero experience, but the real journey towards zero lives lost has only just begun.
  Here are some reminders of what you did during your visit and what you can do in the future.</p>
        <p className='cta mt4 mt5-l f6 f5-l'>SCROLL DOWN</p>
      </div>
    </Section>
  }
}
