import React, { Component } from 'react'
import Slider from 'react-slick'
import Section from '../../section'
import ImageInfoPanel from '../panels/image-info'
import learnerImage from '../../../assets/images/safe-driving/learner.jpg'
import pplaterImage from '../../../assets/images/safe-driving/p-plater.jpg'
import cyclistImage from '../../../assets/images/safe-driving/cyclist.jpg'
import motorcyclistImage from '../../../assets/images/safe-driving/motorcyclist.jpg'
import NextArrowSVG from '../../svg/arrow-next'
import PrevArrowSVG from '../../svg/arrow-prev'
import './index.css'

export default class SafeDrivingExperience extends Component {
  render () {
    const items = this.getItems(this.props.links)
    return <Section id={this.props.id} theme='white'>
      <div className='safe-driving nt5-l mb4'>
        <div className='gallery'>
          <Slider {...sliderSettings}>
            { items.map(item =>
              <div key={item.type}>
                <div className='overflow-hidden'>
                  <ImageInfoPanel
                    htmlTitle={item.title}
                    link={item.link}
                    linkLabel={item.label}
                    imageUrl={item.imageUrl}
                    imageSide='right'
                    noLightbox
                  >
                    <p>{item.body}</p>
                  </ImageInfoPanel>
                </div>
              </div>
            ) }
          </Slider>
        </div>
      </div>
    </Section>
  }

  getItems (links = {}) {
    return [
      {
        type: 'learning',
        id: 'learningtodrive',
        title: 'LEARNING TO DRIVE',
        body: 'Learning to drive is a really important time, both for learners and their supervisors.  There is a lot of great information around to help, so be sure to look at it.',
        imageUrl: learnerImage
      },
      {
        type: 'pdriver',
        id: 'pdrivers',
        title: 'P&nbsp;DRIVERS',
        body: 'When young drivers gain their P1 Licence, they move from the lowest risk of being involved in a fatal car crash to the highest.',
        imageUrl: pplaterImage
      },
      {
        type: 'cyclist',
        id: 'cyclingsafely',
        title: 'CYCLING SAFELY',
        body: 'Selecting the right helmet, lights and clothing as well as when and where you ride can be very important to your safety.',
        imageUrl: cyclistImage
      },
      {
        type: 'motorcyclist',
        id: 'motorcycling',
        title: 'MOTORCYCLE SAFETY',
        body: 'Selecting the right bike, protective clothing and when and where you ride can be very important to your safety.',
        imageUrl: motorcyclistImage
      }
    ].map(item => {
      const cta = links[`cta${item.type}`]
      if (!cta) return item
      const { label, url: link } = cta
      return { ...item, label, link }
    })
  }
}

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slideToShow: 1,
  slideToScroll: 1,
  nextArrow: <NextArrowButton />,
  prevArrow: <PrevArrowButton />,
  customPaging: (i) => {
    return <a><span className='dot' /></a>
  }
}

function NextArrowButton (props) {
  const { className, style, onClick } = props
  return (
    <button className={className} style={{ ...style }} onClick={onClick}>
      <NextArrowSVG />
    </button>
  )
}

function PrevArrowButton (props) {
  const { className, style, onClick } = props
  return (
    <button className={className} style={{ ...style }} onClick={onClick}>
      <PrevArrowSVG />
    </button>
  )
}
