import React from 'react'
import get from 'lodash.get'
import Slider from 'react-slick'
import Tracker from '../../../../gtm/event-tracker'
import DiscsImage from '../../../../../assets/images/create-space/science.safer-speeds.discs.jpg'
import AnswerCorrectSVG from '../../../../svg/answer-correct'
import AnswerIncorrectSVG from '../../../../svg/answer-incorrect'
import './index.css'

export default ({ data: questions, links }) => {
  const cta = getCta(links)
  const totalQuestions = questions.length
  const score = questions.reduce((carry, item) =>
    carry + Number(item.correctSelected)
  , 0)

  return <div className='safer-speeds tl tc-ns pt5 pt4-m pt6-l pb5 pb3-ns'>
    <h1 className='heading1 lh-solid ph4 ph2-m ph0-ns'>SAFER SPEEDS</h1>
    <p className='info fos f6 f5-ns lh-copy mt4 mb4 ph4 ph6-ns'>You answered { score } out of { totalQuestions } questions correctly. Review the questions and answers related to safer speeds.</p>
    <div className='fos mb4 mb5-m mb6-l'>
      <div className='gallery'>
        <Slider {...sliderSettings}>
          { questions.map((item, i) => {
            const isCorrect = item.correctSelected
            const { question, conclusion } = item
            const answer = item.answers[item.correctAnswerIndex]
            return <div key={i} className='ph4 ph5-m ph6-l'>
              <div className='question tc pv4 ph4 ph6-ns br3 mb2 bg-white'>
                <div className='icon mb3'>
                  { isCorrect
                    ? <AnswerCorrectSVG className='correct' />
                    : <AnswerIncorrectSVG className='incorrect' />
                  }
                </div>
                <div className='fgc fw6 f3 f2-ns lh-solid mb3'>QUESTION { i + 1 }<span className='dn di-ns'>&nbsp;OF&nbsp;{ totalQuestions }</span></div>
                <div className='f6 f5-ns fw4 lh-title' dangerouslySetInnerHTML={{ __html: question }} />
              </div>
              <div className='answer tc pv4 ph4 ph6-ns br3'>
                <div className='fgc fw4 f3 f2-ns lh-solid mb3'>CORRECT ANSWER</div>
                <div className='f6 f5-ns fw6 mb3 lh-title' dangerouslySetInnerHTML={{ __html: answer }} />
                <div className='f6 f5-ns lh-title' dangerouslySetInnerHTML={{ __html: conclusion }} />
              </div>
            </div>
          }) }
        </Slider>
      </div>
    </div>
    <div className='pt3 pt0-ns ph4 ph6-l'>
      <img src={DiscsImage} className='discs' />
      <p className='info fos f6 f5-ns lh-copy mt4 mb4 ph5-m'>Read more about what Safer Speeds mean for all road users on Country and Town Centre Roads.</p>
      <Tracker value='create-space-science-safer-speeds-cta-click'>
        <a href={cta.url} target='_blank' className='tbutton clear white'>
          {cta.label}
        </a>
      </Tracker>
    </div>
  </div>
}

const getCta = links => {
  const defaultValue = {
    label: 'READ MORE',
    url: 'https://www.towardszero.vic.gov.au/#safe-speeds'
  }
  return get(links, 'ctasciencesaferspeeds', defaultValue)
}

const sliderSettings = {
  dots: true,
  speed: 500,
  arrows: false,
  infinite: true,
  slideToShow: 1,
  slideToScroll: 1,
  customPaging: (i) => {
    return <a><span className='dot' /></a>
  }
}
