import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { selectContent } from '../../selectors'
import { YOUR_JOURNEY_ID } from '../../helpers/experiences'
import { hiddenFromOutreach } from '../../helpers/outreach'

class Content extends Component {
  getContent (appId) {
    const data = this.props.data
    let content = {}
    // if it's summary section send the entire content
    if (appId === YOUR_JOURNEY_ID) {
      content = data
    } else {
      content = data[appId]
    }

    return content
  }

  getLinks (app) {
    let appId = app
    if (app === 'saferbehave2') appId = 'saferbehave'
    if (app === 'safedriving') appId = 'youngdrivers'

    const data = this.props.data.links
    let links = {}
    data.forEach(link => {
      if (link.category === appId) {
        // remove dash from key for quick usage on js, example:
        // youtubevideo from youtube-video key
        links[link.type.replace(/-/g, '')] = link
      }
    })
    return links
  }

  render () {
    return (
      <Fragment>
        { this.props.children.map((o) => {
          const appId = o.key
          const checkContent = o.props.checkContent
          let content = this.getContent(appId)
          const links = this.getLinks(appId)
          const contentTmp = content

          let outreach = !!(this.props.data.user.source === 'outreach')

          if (content) {
            let keys = Object.keys(contentTmp)
            // check if has atleast one sub key not null
            const hasSubContent = keys.filter(k => content[k] !== null).length > 0
            content = hasSubContent ? content : null
          }

          if (appId === 'header') {
            return React.cloneElement(o, {
              ...o.props,
              outreach
            })
          }

          if (checkContent && !content) return null
          if (outreach && hiddenFromOutreach(o.key)) return null

          // Inject content and also links related to the specific ID
          return React.cloneElement(o, {
            ...o.props,
            user: this.props.data.user,
            outreach,
            content,
            links,
            id: appId
          })
        })
        }
      </Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({})

const mapStateToProps = (state) => ({
  data: selectContent(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(Content)
