import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import store, { history } from './store'
import appSagas from './sagas'
import App from './containers/app'

store.runSaga(appSagas)

const main = document.body.appendChild(document.createElement('div'))
main.className = 'app'

function render () {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    main
  )
}

if (module.hot) {
  module.hot.accept('./containers/app/index', () => render())
}

render()
