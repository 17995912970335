export const selectMain = state => state.get('main')
export const selectLocation = (state) => {
  return state.get('routing').location
}

export const selectIsAuthenticated = (state) => {
  const main = selectMain(state)
  return main.get('authenticated')
}

export const selectIsPosted = (state) => {
  const main = selectMain(state)
  return main.get('posted')
}

export const selectIsLoading = (state) => {
  const main = selectMain(state)
  return main.get('loading')
}

export const selectContent = (state) => {
  const main = selectMain(state)
  return main.get('content').toJS()
}

export const selectCSRFToken = (state) => {
  return selectMain(state).get('_csrf')
}

export function selectMobile (state) {
  return selectMain(state).get('mobile')
}
