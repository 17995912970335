import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { selectMobile } from '../../selectors'
import previewIcon from '../../assets/icons/preview.svg'
import videoIcon from '../../assets/icons/video-icon.svg'
import './index.css'

class LightboxLink extends Component {
  constructor (props) {
    super(props)
    this.state = { openMedia: false, width: 0, height: 0 }
    this.openMedia = this.openMedia.bind(this)
  }

  openMedia () {
    const length = this.props.children.length
    let component = length > 0 ? this.props.children[1] : this.props.children
    let media = length > 0 ? { width: component.props.width, height: component.props.height } : { width: this.refs.child.clientWidth, height: this.refs.child.clientHeight }
    let video = length > 0
    this.props.openMedia(component, media, video)
  }

  render () {
    const { children, video, mobile } = this.props
    const length = children.length || 0
    const el = length > 0 ? children[0] : children
    let icon = length > 0 ? videoIcon : previewIcon
    const hidePreview = mobile && !video
    const extraCls = mobile && video ? 'show-icon' : ''

    if (this.props.icon) {
      // inject custom icon
      icon = this.props.icon
    }

    return (
      <Fragment>
        <div className={`relative media-container`}>
          {
            React.cloneElement(el, {
              className: `${el.props.className} thumbnail`,
              ref: 'child'
            })
          }
          {
            !hidePreview &&
            <div
              onClick={() => this.openMedia()}
              className={`media-overlay absolute flex justify-center items-center flex-column pointer ${extraCls}`}
            >
              <img className='mt4' src={icon} />
              <h5 className='white mt3 fos'>PREVIEW</h5>
            </div>
          }
        </div>
      </Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({})

const mapStateToProps = (state) => ({
  mobile: selectMobile(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(LightboxLink)
