import React from 'react'
import './index.css'

export default function LineDivider (props) {
  const theme = props.theme || ''
  return (
    <div className={`section-line-divider ${theme}`}>
      <div className='line' />
    </div>
  )
}
