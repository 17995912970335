import React, { Component } from 'react'
import ImageInfoPanel from '../panels/image-info'
import Placeholder from '../../../assets/placeholders/body-built.jpg'
import Section from '../../section'
import { isRevoked } from '../../../helpers/revoked'

export default class BodyBuiltExperience extends Component {
  render () {
    const { links, content } = this.props
    const { bicycle, motorbike, pedestrian } = content || { bicycle: null, motorbike: null, pedestrian: null }
    const imageUrl = motorbike || bicycle || pedestrian || Placeholder

    return <Section id={this.props.id} theme='white'>
      <ImageInfoPanel
        title='BODY BUILT TO SURVIVE'
        htmlTitle='<div class="di-m">BODY BUILT </div>TO SURVIVE'
        link={links.cta.url}
        linkLabel={links.cta.label}
        imageUrl={!isRevoked(imageUrl) ? imageUrl : Placeholder}
        noLightbox
      >
        <p>The human body is just too fragile to survive collisions, let alone those at high speeds. With that in mind, we turned the dial up on your body's ability to protect itself and arrived at this: a version of you that is built to survive.</p>
      </ImageInfoPanel>
    </Section>
  }
}
