export const INIT = 'app/INIT'
export const LOAD_DATA = 'app/LOAD_DATA'
export const CHECK_AUTH = 'app/CHECK_AUTH'
export const LOAD_DATA_ERROR = 'app/LOAD_DATA_ERROR'

export const init = (data) => ({
  type: INIT,
  data
})

export const loadData = () => ({
  type: LOAD_DATA
})

export const checkAuth = ({ then }) => ({
  type: CHECK_AUTH,
  then
})

export const errorAuth = () => ({
  type: LOAD_DATA_ERROR
})
