import throttle from 'frame-debounce'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { TweenLite } from 'gsap/TweenMaxBase'
import {
  BodyBuilt,
  CarShowroom,
  Crashlab,
  CrashForces,
  CreativeSpace,
  GlobalOutlook,
  Intro,
  MeetGraham,
  SaferBehaviours,
  SaferSystems,
  SpeedPhysics,
  Summary,
  YourPart,
  SafeDriving,
  VR
} from '../../components/experiences'
import Content from '../content'
import { checkAuth } from '../../actions'
import { selectIsAuthenticated, selectIsLoading } from '../../selectors'
import SectionLineDivider from '../../components/section/line-divider'
import Loading from '../../components/loading'
import {
  ids,
  DO_YOUR_PART_ID,
  SAFER_BEHAVE_ID,
  CAR_SHOWROOM_ID,
  SAFER_SYSTEMS_ID,
  CRASHLAB_ID,
  CRASH_FORCES_ID,
  BODY_BUILT_ID,
  YOUR_JOURNEY_ID,
  INTRO_ID,
  GLOBAL_OUTLOOK_ID,
  MEET_GRAHAM_ID,
  SPEED_PHYSICS_ID,
  CREATIVE_SPACE_ID,
  SAFE_DRIVING_ID,
  VR_RTZ_ID,
  getRouteById,
  getIdByRoute
} from '../../helpers/experiences'
import Header from '../header'
import Footer from '../../components/footer'
import { Lightbox } from '../../components/lightbox'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

class Experiences extends Component {
  constructor (props) {
    super(props)
    this.state = {
      mobile: false,
      openMedia: false,
      lightboxEl: null,
      lBoxWidth: 0,
      lBoxHeight: 0,
      toTopAppear: false
    }
    this.unmounted = false
    this.animating = false
    this.scroll = { y: 0 }
    this.scroll.y = document.documentElement.scrollTo
    this.onResize = throttle(this.onResize.bind(this))
    this.openMedia = this.openMedia.bind(this)
  }

  componentDidMount () {
    this.scrolled = false
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
    this.onResize()
    this.props.checkAuth({
      then: _ => {
        const path = this.getLocationPath()
        const withoutAnimating = true
        this.checkAnchor(path, withoutAnimating)
      }
    })
  }

  componentDidUpdate (prevProps) {
    const path = this.getLocationPath()
    if (path !== prevProps.location.pathname) this.checkAnchor(path)
  }

  getLocationPath () {
    return this.props.location.pathname
  }

  componentWillUnmount () {
    this.unmounted = true
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  }

  onResize () {
    if (this.unmounted) return
    let width = 0
    let height = 0
    let lBoxWidth = 0
    let lBoxHeight = 0
    let padding = 160
    if (window.innerWidth < 960 && !this.state.mobile) {
      this.setState({ mobile: true })
      padding = 130
    } else if (window.innerWidth >= 960 && this.state.mobile) {
      this.setState({ mobile: false })
      padding = 160
    }
    if (this.state.openMedia) {
      width = this.state.lBoxWidth
      height = this.state.lBoxHeight
      lBoxWidth = (window.innerHeight - padding) * (width / height)
      lBoxHeight = window.innerHeight - padding
      if (lBoxWidth > window.innerWidth) {
        lBoxWidth = window.innerWidth
        lBoxHeight = (window.innerWidth) * (height / width)
      }

      this.setState({ lBoxWidth, lBoxHeight })
    }
  }

  onScroll = (e) => {
    if (this.animating) {
      return
    }

    const exp = this.getCurrentExperience()

    if (exp.id === null) return
    const currentId = getIdByRoute(this.props.location.pathname)
    if (currentId && currentId !== exp.id) {
      const newRoute = getRouteById(exp.id)
      // update location
      this.props.history.push(newRoute)
    }
    this.scrolled = true
  }

  getCurrentExperience () {
    const wh2 = window.innerHeight * 0.5
    const r = { id: null, y: 0 } // default id
    let y = window.scrollY || window.pageYOffset
    // add half screen height offset so the middle of the screen can be
    // the delimitation of the current area/section
    y += wh2

    ids.forEach(key => {
      // experience element will use ids
      const el = document.getElementById(key)

      if (!el) return
      const minY = el.offsetTop
      const maxY = (minY + el.offsetHeight)

      if (y >= minY && y <= maxY) {
        r.id = key
        r.y = minY
      }
    })

    return r
  }

  checkAnchor (path, withoutAnimating = false) {
    const current = this.getCurrentExperience()
    let found = getIdByRoute(path)
    let headerOffset = 90

    if (this.state.mobile) {
      headerOffset = 55
    }

    if (found !== current.id) {
      const el = document.getElementById(found)
      if (el) {
        if (!this.state.mobile && !withoutAnimating) {
          this.scrollTo(el, el.offsetTop - headerOffset, 700)
        } else {
          window.scrollTo(0, el.offsetTop - headerOffset)
        }
      }
    }
    this.scrolled = false
  }

  scrollTo (element, to, duration) {
    TweenLite.killTweensOf(this.scroll)

    if (this.state.openMedia) return

    this.animating = true

    this.scroll.y = document.documentElement.scrollTop
    TweenLite.to(this.scroll, 0.7, {
      y: to,
      onUpdate: () => {
        window.scrollTo(0, this.scroll.y)
      },
      onStart: () => {
        this.animating = true
      },
      onComplete: () => {
        TweenLite.delayedCall(0.4, () => { this.animating = false })
      }
    })
  }

  openMedia (type, media, video) {
    let width = 0
    let height = 0
    let lBoxWidth = 0
    let lBoxHeight = 0
    let padding = 160
    if (type && media) {
      width = media.width
      height = media.height
      lBoxWidth = (window.innerHeight - padding) * (width / height)
      lBoxHeight = window.innerHeight - padding
      if (lBoxWidth > window.innerWidth) {
        lBoxWidth = window.innerWidth
        lBoxHeight = (window.innerWidth) * (height / width)
      }
    }

    let el = document.body

    if (!this.state.openMedia) {
      el.className += ' stop-scroll'
      this.setState({
        openMedia: !this.state.openMedia,
        lBoxHeight: lBoxHeight,
        lBoxWidth: lBoxWidth,
        lightboxEl: type,
        video: video
      })
    } else {
      el.className = ''
      this.setState({
        openMedia: !this.state.openMedia
      })
    }
  }

  render () {
    const { authenticated, loading } = this.props

    if (!authenticated) return null

    if (loading) return <Loading />

    const dynamicComponents = [
      <Summary key={YOUR_JOURNEY_ID} />,
      <Header key='header' mobile={this.state.mobile} />,
      <BodyBuilt key={BODY_BUILT_ID} />,
      <CrashForces key={CRASH_FORCES_ID} />,
      <SaferSystems key={SAFER_SYSTEMS_ID} />,
      <Crashlab key={CRASHLAB_ID} />,
      <SaferBehaviours key={SAFER_BEHAVE_ID} />,
      <YourPart key={DO_YOUR_PART_ID} openMedia={this.openMedia} />,
      <MeetGraham key={MEET_GRAHAM_ID} />,
      <VR key={VR_RTZ_ID} openMedia={this.openMedia} />,
      <SpeedPhysics key={SPEED_PHYSICS_ID} openMedia={this.openMedia} />,
      <CarShowroom key={CAR_SHOWROOM_ID} />,
      <GlobalOutlook key={GLOBAL_OUTLOOK_ID} />,
      <SafeDriving key={SAFE_DRIVING_ID} />,
      <CreativeSpace key={CREATIVE_SPACE_ID} openMedia={this.openMedia} checkContent />,
      <Footer key='footer' />
    ]

    return (
      <Fragment>
        <Intro mobile={this.state.mobile} id={INTRO_ID} />
        <SectionLineDivider theme='blue' />
        <Content>{dynamicComponents}</Content>
        <ReactCSSTransitionGroup
          transitionName='fade'
          transitionEnterTimeout={400}
          transitionLeaveTimeout={400}
          transitionAppearTimeout={400}
          transitionAppear
        >
          { this.state.openMedia &&
            <Lightbox openMedia={this.openMedia}
              video={this.state.video}
              image={this.state.lightboxEl}
              width={this.state.lBoxWidth}
              height={this.state.lBoxHeight} /> }
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  checkAuth: ({ then }) => dispatch(checkAuth({ then }))
})

const mapStateToProps = (state) => ({
  authenticated: selectIsAuthenticated(state),
  loading: selectIsLoading(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(Experiences)
