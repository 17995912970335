import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { selectLocation } from '../../selectors'
import { getRouteById } from '../../helpers/experiences'
import './index.css'

class Subnav extends Component {
  render () {
    return (
      <Fragment>
        <div className={this.props.className}>
          { this.props.children.map((o) => {
            const route = getRouteById(o.key)
            return React.cloneElement(o, {
              to: route,
              selected: this.props.location.pathname === route,
              className: o.props.className
            })
          })
          }
        </div>
      </Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({})

const mapStateToProps = (state) => ({
  location: selectLocation(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(Subnav)
