import {
  BODY_BUILT_ID,
  BODY_BUILT_ROUTE,
  CAR_SHOWROOM_ID,
  CAR_SHOWROOM_ROUTE,
  CRASHLAB_ID,
  CRASHLAB_ROUTE,
  CRASH_FORCES_ID,
  CRASH_FORCES_ROUTE,
  EXPERIENCES_ROUTE,
  GLOBAL_OUTLOOK_ID,
  GLOBAL_OUTLOOK_ROUTE,
  INTRO_ROUTE,
  MEET_GRAHAM_ID,
  MEET_GRAHAM_ROUTE,
  SAFER_BEHAVE_ID,
  SAFER_BEHAVE_ROUTE,
  SAFER_SYSTEMS_ID,
  SAFER_SYSTEMS_ROUTE,
  SPEED_PHYSICS_ID,
  SPEED_PHYSICS_ROUTE,
  VR_RTZ_ID,
  VR_RTZ_ROUTE,
  YOUR_JOURNEY_ROUTE,
  DO_YOUR_PART_ID,
  DO_YOUR_PART_ROUTE
} from '../../helpers/experiences'
import './index.css'

export const JOURNEY_LINKS = [
  BODY_BUILT_ID,
  CRASH_FORCES_ID,
  SAFER_SYSTEMS_ID,
  CRASHLAB_ID,
  SAFER_BEHAVE_ID,
  DO_YOUR_PART_ID
]

export const OTHER_EXHIBITS_LINKS = [
  MEET_GRAHAM_ID,
  VR_RTZ_ID,
  SPEED_PHYSICS_ID,
  CAR_SHOWROOM_ID,
  GLOBAL_OUTLOOK_ID
]

export function isJourneyPath (path) {
  return (
    path === EXPERIENCES_ROUTE ||
    path === INTRO_ROUTE ||
    path === YOUR_JOURNEY_ROUTE ||
    path === BODY_BUILT_ROUTE ||
    path === CRASH_FORCES_ROUTE ||
    path === SAFER_SYSTEMS_ROUTE ||
    path === CRASHLAB_ROUTE ||
    path === SAFER_BEHAVE_ROUTE ||
    path === DO_YOUR_PART_ROUTE
  )
}

export function isOtherExhibitsPath (path) {
  return (
    path === MEET_GRAHAM_ROUTE ||
    path === VR_RTZ_ROUTE ||
    path === SPEED_PHYSICS_ROUTE ||
    path === CAR_SHOWROOM_ROUTE ||
    path === GLOBAL_OUTLOOK_ROUTE
  )
}

export function getIEVersion () {
  const sAgent = window.navigator.userAgent
  const Idx = sAgent.indexOf('MSIE')

  // If IE, return version number.
  if (Idx > 0) {
    return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf('.', Idx)))
  } else if (navigator.userAgent.match(/Trident\/7\./)) {
    return 11
  } else {
    return 0
  } // It is not IE
}
