import React from 'react'
import './selected-speed.css'

export default function SelectedSpeed (props) {
  if (!props.data) return null
  const { image, copy } = props.data

  return <div className='selected-speed-panel flex flex-column flex-row-l items-center justify-center-ns mt6 mb0 mb5-m'>
    <img src={image} className='image pa0' />
    <div className='info pa0 pb5 pb0-m'>
      <span className='background ' />
      <h1 className='info-title lh-solid mt4 mt5-m mt0-l ph4 ph0-ns'>
        YOUR SELECTED SPEED
      </h1>
      <div className='info-details fos lh-copy ph4 ph0-ns mt3 mt4-ns'>
        <p>{copy}</p>
      </div>
    </div>
  </div>
}
