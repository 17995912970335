import React, { Component, Fragment } from 'react'
import store from '../../../store'
import { selectContent } from '../../../selectors'
import Section from '../../section'
import Tracker from '../../gtm/event-tracker'
import Pledge from './pledge'
import './index.css'

const br = '<br class="dn db-ns" />'
const TITLE_MYSELF = `TO KEEP ${br}MYSELF SAFE`
const TITLE_FAMILY = `TO KEEP MY ${br}FAMILY SAFE`
const TITLE_COMMUNITY = `TO KEEP MY ${br}COMMUNITY SAFE`

export default class YourPartExperience extends Component {
  constructor (props) {
    super(props)
    this.state = {
      cta: this.props.links.cta,
      pledges: this.parsePledges()
    }
  }

  parsePledges () {
    const c = this.props.content
    const pledges = []

    if (!c || !c.pledges) {
      return pledges
    }

    const { myself = [], family = [], community = [] } = c.pledges
    const linksMap = new Map()
    const links = selectContent(store.getState()).links

    // create a map with all links for yourparttablet app
    links.forEach((item, i) => {
      if (item.category === 'yourparttablet') {
        linksMap.set(item.type, Object.assign({}, item))
      }
    })

    const injectLinks = (list = []) => {
      return list.map(item => {
        if (typeof item === 'object' && item.linkId) {
          var { url = '' } = linksMap.get(item.linkId)

          if (url) {
            // inject url based on linkId of the current pledge
            return Object.assign({ url }, item)
          }
        }
        return item
      })
    }

    if (myself.length > 0) {
      pledges.push(this.createPledge(TITLE_MYSELF, injectLinks(myself)))
    }

    if (family.length > 0) {
      pledges.push(this.createPledge(TITLE_FAMILY, injectLinks(family)))
    }

    if (community.length > 0) {
      pledges.push(this.createPledge(TITLE_COMMUNITY, injectLinks(community)))
    }

    return pledges
  }

  createPledge (title, list) {
    return <Pledge
      key={`pl-${title.toLowerCase()}`}
      title={title}
      list={list}
    />
  }

  shouldComponentUpdate (nextProps, nextState) {
    return false
  }

  render () {
    // check how many categories the user has selected.
    const totalCategories = this.state.pledges.length

    return <Section id={this.props.id} className='your-part' theme='blue'>
      <div className='tl tc-ns pv5 ph4 ph2-m ph0-ns'>
        <h1 className='heading1 lh-solid'>DO YOUR PART</h1>
        <p className='info center fos f6 f5-ns fw6-ns lh-copy mt3 mt4-ns ph0 ph4-ns'>The Road to Zero starts with each of us. By doing your part, we're one step closer to being the first generation with zero deaths on Victorian roads.</p>
        <Tracker value='do-your-part-cta-click'>
          <a href={this.state.cta.url} target='_blank' className='tbutton clear white flex mt4 mt5-ns'>{this.state.cta.label}</a>
        </Tracker>
        { totalCategories > 0
          ? <Fragment>
            <h3 className='dn db-ns fos heading4 mt6 mb4 mh4'>DOING YOUR PART</h3>
            <h3 className='dn-ns heading2 mt5 pv3'>DOING<br />YOUR PART</h3>
            {this.state.pledges}
            <h2 className='heading2 mt4 mt5-ns mt6-ns mh4-ns'>THANK YOU</h2>
            <p className='mh4-ns mt3 mt4-ns pt1 pb3 pv0-ns lh-copy fos f6 f5-ns fw6-ns'>
                Road safety is everyone's responsibility.
              <br />
                Together we can achieve zero lives lost on our roads.
            </p>
          </Fragment>
          : null }
      </div>
    </Section>
  }
}
