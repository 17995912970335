// import throttle from 'frame-debounce'
import React, { Component, Fragment } from 'react'
import { selectContent, selectLocation } from '../../selectors'
import { connect } from 'react-redux'
import { Desktop, Mobile } from '../../components/header'
import { isRevoked } from '../../helpers/revoked'

class Header extends Component {
  render () {
    const hasCreative = this.hasCreativeContentSection(this.props.content)

    return (
      <Fragment>
        { this.props.mobile &&
          <Mobile
            links={this.props.links}
            openMedia={this.props.openMedia}
            hasCreative={hasCreative}
            outreach={this.props.outreach} />
        }
        { !this.props.mobile &&
          <Desktop
            links={this.props.links}
            openMedia={this.props.openMedia}
            hasCreative={hasCreative}
            outreach={this.props.outreach} />
        }
      </Fragment>
    )
  }

  hasCreativeContentSection (content) {
    const { createspace } = content
    if (createspace === null) return false
    const { health } = createspace
    if (health && isRevoked(health.url)) return false
    return true
  }
}

const mapDispatchToProps = (dispatch) => ({

})

const mapStateToProps = (state) => ({
  content: selectContent(state),
  location: selectLocation(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
