import React, { Component } from 'react'
import ImageInfoPanel from '../panels/image-info'
import Section from '../../section'
import image from '../../../assets/images/speed-physics/speed_physics.jpg'

export default class SpeedPhysicsExperience extends Component {
  render () {
    const { youtubevideo } = this.props.links
    return <Section id={this.props.id} theme='white'>
      <ImageInfoPanel
        title='SPEED &amp; PHYSICS'
        htmlTitle='<div class="nowrap-ns">SPEED &amp; PHYSICS</div>'
        link=''
        linkLabel={youtubevideo.label}
        imageUrl={image}
        videoUrl={youtubevideo.url}
        openMedia={this.props.openMedia}
        noLightbox
      >
        <p>Different types of crashes pose different risks to our fragile bodies. Add speed to the equation, and the risks get higher still. That’s why good safety features are of the utmost importance.</p>
      </ImageInfoPanel>
    </Section>
  }
}
