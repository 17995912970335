import React, { Component } from 'react'
import Slider from 'react-slick'
import GalleryItem from './gallery-item'

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slideToShow: 1,
  slideToScroll: 1,
  arrows: false,
  customPaging: (i) => {
    return <a><span className='dot' /></a>
  }
}

export default class Gallery extends Component {
  render () {
    const itemsData = this.props.items || []
    const items = itemsData.map((content, i) => {
      if (content) {
        return <GalleryItem
          key={`ss-gi-${i}`}
          content={content}
          openMedia={this.props.openMedia}
        />
      }
      return null
    })

    return (
      <div className='gallery mt5'>
        <Slider {...settings}>{items}</Slider>
      </div>
    )
  }
}
