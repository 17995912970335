export const EXPERIENCES_ROUTE = '/experiences'

export const BODY_BUILT_ID = 'bodybuilt'
export const BODY_BUILT_TITLE = 'BODY BUILT TO SURVIVE'
export const BODY_BUILT_ROUTE = `${EXPERIENCES_ROUTE}/body-built-to-survive`

export const CAR_SHOWROOM_ID = 'carshowroom'
export const CAR_SHOWROOM_TITLE = 'CAR SHOWROOM'
export const CAR_SHOWROOM_ROUTE = `${EXPERIENCES_ROUTE}/car-showroom`

export const CRASH_FORCES_ID = 'crashforces'
export const CRASH_FORCES_TITLE = 'CRASH FORCES'
export const CRASH_FORCES_ROUTE = `${EXPERIENCES_ROUTE}/crash-forces`

export const CRASHLAB_ID = 'crashlab'
export const CRASHLAB_TITLE = 'CRASHLAB'
export const CRASHLAB_ROUTE = `${EXPERIENCES_ROUTE}/crashlab`

export const CREATIVE_SPACE_ID = 'createspace'
export const CREATIVE_SPACE_TITLE = 'YOUR CREATIVE CONTENT'
export const CREATIVE_SPACE_ROUTE = `${EXPERIENCES_ROUTE}/your-creative-content`

export const CREATIVE_SPACE_PHYSICS_ID = 'createspacephysics'
export const CREATIVE_SPACE_PHYSICS_TITLE = 'YOUR CREATIVE CONTENT'
export const CREATIVE_SPACE_PHYSICS_ROUTE = `${EXPERIENCES_ROUTE}/your-creative-content-physics`

export const CREATIVE_SPACE_PHYSICS_RESULT_ID = 'createspacephysicsresult'
export const CREATIVE_SPACE_PHYSICS_RESULT_TITLE = 'YOUR CREATIVE CONTENT'
export const CREATIVE_SPACE_PHYSICS_RESULT_ROUTE = `${EXPERIENCES_ROUTE}/your-creative-content-physics-result`

export const CREATIVE_SPACE_PHYSICS_TREATMENTS_ID = 'createspacephysicstreatments'
export const CREATIVE_SPACE_PHYSICS_TREATMENTS_TITLE = 'YOUR CREATIVE CONTENT'
export const CREATIVE_SPACE_PHYSICS_TREATMENTS_ROUTE = `${EXPERIENCES_ROUTE}/your-creative-content-physics-treatments`

export const GLOBAL_OUTLOOK_ID = 'globaloutlook'
export const GLOBAL_OUTLOOK_TITLE = 'GLOBAL OUTLOOK'
export const GLOBAL_OUTLOOK_ROUTE = `${EXPERIENCES_ROUTE}/global-outlook`

export const INTRO_ID = 'intro'
export const INTRO_TITLE = 'EXPERIENCE SPACE'
export const INTRO_ROUTE = `${EXPERIENCES_ROUTE}/intro`

export const MEET_GRAHAM_ID = 'meetgraham'
export const MEET_GRAHAM_TITLE = 'MEET GRAHAM'
export const MEET_GRAHAM_ROUTE = `${EXPERIENCES_ROUTE}/meet-graham`

export const SAFER_BEHAVE_ID = 'saferbehave2'
export const SAFER_BEHAVE_TITLE = 'SAFER BEHAVIOURS'
export const SAFER_BEHAVE_ROUTE = `${EXPERIENCES_ROUTE}/safer-behaviours`

export const SAFER_SYSTEMS_ID = 'safersystems'
export const SAFER_SYSTEMS_TITLE = 'SAFER SYSTEMS'
export const SAFER_SYSTEMS_ROUTE = `${EXPERIENCES_ROUTE}/safer-systems`
export const SAFER_SYSTEMS_LEGACY_ROUTE = `${EXPERIENCES_ROUTE}/safe-environment`

export const SPEED_PHYSICS_ID = 'speedphysics'
export const SPEED_PHYSICS_TITLE = 'SPEED & PHYSICS'
export const SPEED_PHYSICS_ROUTE = `${EXPERIENCES_ROUTE}/speed-and-physics`

export const VR_RTZ_ID = 'vr'
export const VR_RTZ_TITLE = 'ROAD TRIP TO ZERO'
export const VR_RTZ_ROUTE = `${EXPERIENCES_ROUTE}/road-trip-to-zero`

export const SAFE_DRIVING_ID = 'safedriving'
export const SAFE_DRIVING_TITLE = 'SAFE DRIVING'
export const SAFE_DRIVING_ROUTE = `${EXPERIENCES_ROUTE}/safe-driving`

export const YOUR_JOURNEY_ID = 'yourjourney'
export const YOUR_JOURNEY_TITLE = 'RELIVE YOUR JOURNEY'
export const YOUR_JOURNEY_ROUTE = `${EXPERIENCES_ROUTE}/your-journey`

export const DO_YOUR_PART_ID = 'yourparttablet'
export const DO_YOUR_PART_TITLE = 'DO YOUR PART'
export const DO_YOUR_PART_ROUTE = `${EXPERIENCES_ROUTE}/do-your-part`

const list = [
  [
    BODY_BUILT_ID,
    BODY_BUILT_TITLE,
    BODY_BUILT_ROUTE
  ], [
    CAR_SHOWROOM_ID,
    CAR_SHOWROOM_TITLE,
    CAR_SHOWROOM_ROUTE
  ], [
    CRASHLAB_ID,
    CRASHLAB_TITLE,
    CRASHLAB_ROUTE
  ], [
    CRASH_FORCES_ID,
    CRASH_FORCES_TITLE,
    CRASH_FORCES_ROUTE
  ], [
    CREATIVE_SPACE_ID,
    CREATIVE_SPACE_TITLE,
    CREATIVE_SPACE_ROUTE
  ], [
    CREATIVE_SPACE_PHYSICS_ID,
    CREATIVE_SPACE_PHYSICS_TITLE,
    CREATIVE_SPACE_PHYSICS_ROUTE
  ], [
    CREATIVE_SPACE_PHYSICS_RESULT_ID,
    CREATIVE_SPACE_PHYSICS_RESULT_TITLE,
    CREATIVE_SPACE_PHYSICS_RESULT_ROUTE
  ], [
    CREATIVE_SPACE_PHYSICS_TREATMENTS_ID,
    CREATIVE_SPACE_PHYSICS_TREATMENTS_TITLE,
    CREATIVE_SPACE_PHYSICS_TREATMENTS_ROUTE
  ], [
    GLOBAL_OUTLOOK_ID,
    GLOBAL_OUTLOOK_TITLE,
    GLOBAL_OUTLOOK_ROUTE
  ], [
    INTRO_ID,
    INTRO_TITLE,
    INTRO_ROUTE
  ], [
    MEET_GRAHAM_ID,
    MEET_GRAHAM_TITLE,
    MEET_GRAHAM_ROUTE
  ], [
    SAFER_BEHAVE_ID,
    SAFER_BEHAVE_TITLE,
    SAFER_BEHAVE_ROUTE
  ], [
    SAFER_SYSTEMS_ID,
    SAFER_SYSTEMS_TITLE,
    SAFER_SYSTEMS_ROUTE
  ], [
    SPEED_PHYSICS_ID,
    SPEED_PHYSICS_TITLE,
    SPEED_PHYSICS_ROUTE
  ], [
    VR_RTZ_ID,
    VR_RTZ_TITLE,
    VR_RTZ_ROUTE
  ], [
    SAFE_DRIVING_ID,
    SAFE_DRIVING_TITLE,
    SAFE_DRIVING_ROUTE
  ], [
    YOUR_JOURNEY_ID,
    YOUR_JOURNEY_TITLE,
    YOUR_JOURNEY_ROUTE
  ], [
    DO_YOUR_PART_ID,
    DO_YOUR_PART_TITLE,
    DO_YOUR_PART_ROUTE
  ]
]

export const routes = []
export const titles = []
export const ids = []

const routeIdMap = new Map()
const idRouteMap = new Map()
const routeTitleMap = new Map()
const idTitleMap = new Map()

list.forEach(([id, title, route]) => {
  ids.push(id)
  titles.push(title)
  routes.push(route)
  routeIdMap.set(route, id)
  idRouteMap.set(id, route)
  routeTitleMap.set(route, title)
  idTitleMap.set(id, title)
})

export function getRouteById (id) {
  return idRouteMap.get(id)
}

export function getIdByRoute (route) {
  return routeIdMap.get(route)
}

export function getTitleByRoute (route) {
  return routeTitleMap.get(route)
}

export function getTitleByid (route) {
  return idTitleMap.get(route)
}
