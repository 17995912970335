import React, { Component } from 'react'
import ImageInfoPanel from '../panels/image-info'
import Placeholder from '../../../assets/placeholders/car-showroom.jpg'
import Section from '../../section'

export default class CarShowroomExperience extends Component {
  render () {
    const cta = this.getCta()
    return <Section id={this.props.id} theme='white'>
      <ImageInfoPanel
        title='CAR SHOWROOM'
        link={cta.url}
        linkLabel={cta.label}
        imageUrl={Placeholder}
        imageSide='right'
        noLightbox
      >
        <p>Choosing which car to buy is a big decision. Whether you buy new or used, safety should be top of the shopping list.</p>
      </ImageInfoPanel>
    </Section>
  }

  getCta () {
    return this.props.links && this.props.links.cta
      ? this.props.links.cta
      : { label: 'READ MORE', url: 'http://www.howsafeisyourcar.com.au/Browse-Cars' }
  }
}
