import axios from 'axios'
import get from 'lodash.get'
import { push } from 'react-router-redux'
import { takeLatest, put, select, call } from 'redux-saga/effects'
import { init, errorAuth, LOAD_DATA, CHECK_AUTH } from './actions'
import { selectLocation, selectIsAuthenticated } from './selectors'
import mapCreateSpaceContent from './helpers/create-space-content-mapping'
import { isRevoked } from './helpers/revoked'

export default function * () {
  yield takeLatest(LOAD_DATA, loadData)
  yield takeLatest(CHECK_AUTH, checkAuth)
}

export function * loadData () {
  console.log('Loading data…')
  try {
    let resultId, from
    const location = yield select(selectLocation)
    if (location) {
      const paths = location.pathname.split('/')
      if (paths.length >= 2 && paths[1] === 'welcome') {
        resultId = paths[2]
        from = 'route'
        if (!resultId) {
          yield put(push('/error'))
          return true
        }
      }
    }
    if (!resultId) {
      resultId = getResultId()
      from = 'local'
    }
    try {
      const data = yield call(loadResultData, resultId)
      yield put(init(data))
      setResultId(resultId)
      const url = getRedirectUrl(data)
      console.log(`Redirecting to url [${url}]…`)
      yield put(push(url))
      return true
    } catch (err) {
      console.warn(`Error loading result for ${from} id [${resultId}].`)
      clearResultId()
      yield put(errorAuth())
      yield put(push('/error'))
      return true
    }
  } catch (err) {
    console.warn('Error loading data.', err)
  }
}

function * checkAuth ({ then = null }) {
  console.log('Checking authentication…')
  try {
    if (yield select(selectIsAuthenticated)) return confirmAuth(then)
    const resultId = getResultId()
    if (resultId) {
      try {
        const data = yield call(loadResultData, resultId)
        yield put(init(data))
        return confirmAuth(then)
      } catch (err) {
        console.warn(`Error loading result for local auth id [${resultId}].`)
        clearResultId()
        yield put(errorAuth())
        yield put(push('/error'))
      }
    }
    console.log('Authentication failed.')
    yield put(push('/error'))
  } catch (err) {
    console.warn('Error checking authentication.', err)
  }
}

function confirmAuth (then) {
  console.log('Authentication confirmed.')
  if (typeof then === 'function') then()
  return true
}

function * loadResultData (resultId) {
  const response = yield call(getResults, resultId)
  const data = mapCreateSpaceContent(response.data)
  const user = getUser(data)
  if (user) console.log(`Result [${resultId}] for user [${user._id}] loaded.`)
  return data
}

function * getResults (resultId) {
  return yield axios.get(`/api/result/${resultId}`)
}

function getRedirectUrl (data) {
  const user = getUser(data)
  return user && user.location === 'create-space' && !creativeSectionHasBeenRevoked(data)
    ? '/experiences/your-creative-content'
    : '/experiences/intro'
}

function creativeSectionHasBeenRevoked (data) {
  const createspace = get(data, 'data.createspace')
  if (!createspace) return false
  const { health } = createspace
  if (health && isRevoked(health.url)) return true
  return false
}

function getUser (data) {
  return get(data, 'data.user')
}

function getResultId () {
  return window.localStorage.getItem(resultKey)
}

function setResultId (resultId) {
  window.localStorage.setItem(resultKey, resultId)
}

function clearResultId () {
  window.localStorage.removeItem(resultKey)
}

const resultKey = 'tac-result-id'
