import React from 'react'
import get from 'lodash.get'
import Section from '../../../section'
import Intro from './intro'
import SpeedAndFriction from './speed-and-friction'
import SaferSpeeds from './safer-speeds'
import WetAndDry from './wet-and-dry'

export default function ({ id, user, links, data }) {
  const { experiment, quiz } = data
  return <Section id={id} theme='blue'>
    <Intro />
    <SpeedAndFriction />
    { hasExperiment(experiment) && <WetAndDry {...{ user, data: experiment }} /> }
    { hasQuestions(quiz) && <SaferSpeeds {...{ data: quiz.questions, links }} /> }
  </Section>
}

const hasExperiment = experiment => {
  return Array.isArray(experiment) && !!experiment.filter(Boolean).length
}

const hasQuestions = quiz => {
  const questions = get(quiz, 'questions')
  return Array.isArray(questions) && !!questions.filter(Boolean).length
}
