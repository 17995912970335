import React from 'react'

export default function TickSVG (props) {
  const color = props.color || '#ffffff'
  return (
    <svg className='mr2' width='23px' height='17px' viewBox='0 0 23 17' version='1.1' xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink'>
      <path fill={color} d='M10.272347,16.628231 C11.4241157,15.5427437 14.0238042,13.0562151 18.0714126,9.16864525 L23.4951497,3.9647894 C24.1490669,3.33738242 24.1705576,2.29866401 23.5431506,1.64474688 C22.8620868,0.963186573 21.7990924,0.95447409 21.131095,1.60092324 L9.7235408,12.6404918 C9.30901485,13.0416459 8.65200055,13.0448981 8.23352361,12.6478673 L4.93784999,9.52109061 C4.09750266,8.68074328 3.39176094,8.38336171 2.76263627,8.38336171 C2.4567021,8.38336171 2.08836713,8.64512289 1.65763135,9.16864525 C1.37047417,9.44979349 1.29868487,9.82530635 1.44226346,10.2951838 C1.58584206,10.7650613 3.41995149,12.876077 6.94459175,16.628231 C8.31486392,17.5427437 9.42411565,17.5427437 10.272347,16.628231 Z' id='Path-32' />
    </svg>
  )
}
