import { Component } from 'react'

export default class Home extends Component {
  componentWillMount () {
    window.location.replace('http://www.roadtozero.vic.gov.au')
  }

  render () {
    return null
  }
}
