import React from 'react'
import AvatarImage from '../../../assets/placeholders/user-avatar/safer-behaviours.question.png'

export default function Question (props) {
  const imageUrl = props.avatarUrl || AvatarImage
  const questionNum = props.num || 1
  const text = props.text || ''

  return (
    <div className='question flex mt3'>
      <div className='avatar dn flex-ns'>
        <img src={imageUrl} />
      </div>
      <div className='question-content fos tl pa4 pb3'>
        <p>
          <span className='fgc fw4 f4 f3-m f2-l'>QUESTION {questionNum}</span>
        </p>
        <p className='m2 pb2 lh-copy mt2 mt1-m mt0-l mb1'>{text}</p>
      </div>
    </div>
  )
}
