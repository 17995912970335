import React from 'react'
import Question from './question'
import Answer from './answer'
import { isRevoked } from '../../../helpers/revoked'
import AvatarImage1 from '../../../assets/placeholders/user-avatar/1.png'
import AvatarImage2 from '../../../assets/placeholders/user-avatar/2.png'
import AvatarImage3 from '../../../assets/placeholders/user-avatar/3.png'

export default function Quiz (props) {
  const quizList = []
  const { content } = props
  const height = props.className ? props.className : ''
  let entries = Object.keys(content).sort()
  const avatarUrl = !isRevoked(props.avatarUrl) ? props.avatarUrl : randomAvatar()
  entries.forEach((item, i) => {
    if (content[item]) {
      const o = content[item]
      quizList.push(
        <Question
          key={`q-${i}`}
          num={i + 1}
          text={o.question}
        />
      )
      quizList.push(
        <Answer
          key={`a-${i}`}
          userText={o.answer}
          correctText={o.correctAnswer}
          avatarUrl={avatarUrl}
        />
      )
    }
  })

  return (
    <div className={`safer-behaviour-quiz fgc flex flex-column mt2 mt3-l ${height}`}>
      {quizList}
    </div>
  )
}

const randomAvatar = () => {
  const avatars = [AvatarImage1, AvatarImage2, AvatarImage3]
  return avatars[Math.floor(Math.random() * avatars.length)]
}
