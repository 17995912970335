import React from 'react'
import Section from '../../../section'
import ImageInfoPanel from '../../panels/image-info'
import HealthImage from '../../../../assets/images/create-space/health.jpg'

export default function ({ id, data, openMedia }) {
  return <Section id={id} theme='blue'>
    <ImageInfoPanel
      title='YOUR AD CAMPAIGN'
      linkLabel='DOWNLOAD YOUR VIDEO'
      imageUrl={HealthImage}
      videoUrl={data.url}
      download
      imageSide='right'
      theme='blue'
      openMedia={openMedia}
    >
      <div>
        <p>Nice Work!</p>
        <p className='mt3'>We have saved you a copy of your ad campaign to download and share with your family and friends.</p>
      </div>
    </ImageInfoPanel>
  </Section>
}
