import React, { Component } from 'react'
import ImageInfoPanel from '../panels/image-info'
import Placeholder from '../../../assets/placeholders/crash-forces.jpg'
import Section from '../../section'
import { isRevoked } from '../../../helpers/revoked'

export default class CrashForcesExperience extends Component {
  render () {
    const imageUrl = this.props.content && this.props.content.url ? this.props.content.url : Placeholder
    const { cta } = this.props.links
    const hasBeenRevoked = isRevoked(imageUrl)
    let text = 'The impact of speed on the human body can be shocking.'
    if (!hasBeenRevoked) text += ' Here’s a photo of you to prove it.'
    return <Section id={this.props.id} theme='white'>
      <ImageInfoPanel
        title='CRASH FORCES ELEVATOR EXPERIENCE'
        htmlTitle='CRASH FORCES <div class="dn di-l">ELEVATOR EXPERIENCE</div>'
        link={cta.url}
        linkLabel={cta.label}
        imageUrl={hasBeenRevoked ? Placeholder : imageUrl}
        imageSide='right'
        noLightbox
      >
        <p>{ text }</p>
      </ImageInfoPanel>
    </Section>
  }
}
