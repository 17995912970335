import { Component } from 'react'
import { connect } from 'react-redux'
import { loadData } from '../../actions'

class Welcome extends Component {
  componentWillMount () {
    this.props.loadData()
  }

  render () {
    return null
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadData: () => dispatch(loadData())
})

export default connect(null, mapDispatchToProps)(Welcome)
