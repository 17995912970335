import get from 'lodash/get'

// CodeOnCanvas data example
// https://bitbucket.org/codeoncanvas/ds-tac-science-3/wiki/Backend%20Comms

// Downstream Assets Checklist
// https://docs.google.com/spreadsheets/d/1NsQFFUFDU8XMVk761-qMoVYCUARrz3KEh6LZMVzWWmY/edit#gid=1331051571

// const staticUrl = 'static/' // document.location.origin

const A = {
  'A1': 'Pod A01\'s',
  'A2': 'Pod A02\'s',
  'A3': 'Pod A03\'s',
  'A4': 'Pod A04\'s',
  'A5': 'Pod A05\'s',
  'B1': 'Pod B01\'s',
  'B2': 'Pod B02\'s',
  'B3': 'Pod B03\'s',
  'B4': 'Pod B04\'s',
  'B5': 'Pod B05\'s'
}

// const B = {
//   'Town Centre': 'Town Centre',
//   'Local Street': 'Local Street',
//   'Arterial Road': 'Arterial Road',
//   'Rural Highway': 'Rural Highway'
// }

const C = {
  'Town Centre': 'Due to a variety and high frequency of road users, creating a safe road environment in a town centre has a number of challenges. Road Engineers must consider appropriate safety treatments for vulnerable users such as pedestrians and cyclists.',
  'Local Street': 'Due to a variety of road users, creating a safe road environment in local streets has a number of challenges. Road Engineers must consider appropriate safety treatments for vulnerable users such as pedestrians and cyclists.',
  'Arterial Road': 'Vehicles are the most prominent road user on arterial roads. These roads allow for higher speeds, which increases the risk of serious injury, Road Engineers must consider effectively reducing the likelihood of a crash and its severity.',
  'Rural Highway': 'Vehicles are the most prominent road user on rural highways. These roads allow for higher speeds, which increases the risk of serious injury, Road Engineers must consider effectively reducing the likelihood of a crash and its severity.'
}

// const D = Dynamic image
const E = 'In the physics experiment, your group explored the correlation between speed, environmental conditions, and their impacts on stopping distances. Were you surprised by the impact that higher speeds had on stopping distances? What about when the roads were wet?'

// const F = Dynamic image
const G = 'As part of the physics experiment, you were asked to predict the stopping distances, did you notice any trends? What variables had the most impact?'

const H = {
  'S-TC1': 'static/create-space/h/TC_ROUNDABOUT_FENCING_SEGREGATED_1.mp4',
  'S-TC2': 'static/create-space/h/TC_ROUNDABOUT_WOMBAT_SEGREGATED_1.mp4',
  'S-TC3': 'static/create-space/h/TC_SAFESPEED_V2_1_1.mp4',
  'S-TC4': 'static/create-space/h/TC_TRAFFICSIGNALS_FENCING_SEGREGATED_1.mp4',
  'S-TC5': 'static/create-space/h/TC_TRAFFICSIGNALS_WOMBAT_SEGREGATED_1.mp4',
  'S-LS1': 'static/create-space/h/RS_ROUNDABOUT_WOMBAT_SEGREGATED_1.mp4',
  'S-LS2': 'static/create-space/h/RS_SAFESPEED_V2_1_1.mp4',
  'S-LS3': 'static/create-space/h/RS_SAFETYPLATFORM_WOMBAT_SEGREGATED_1.mp4',
  'S-AR1': 'static/create-space/h/AR_ROUNDABOUT_BARRIERS_BARRIERS_v004_1.mp4',
  'S-AR2': 'static/create-space/h/AR_ROUNDABOUT_BARRIERS_SHRUBS_v004_1.mp4',
  'S-AR3': 'static/create-space/h/AR_ROUNDABOUT_SHRUBS_SHRUBS_v004_1.mp4',
  'S-AR4': 'static/create-space/h/AR_ROUNDABOUT_SHURBS_BARRIERS_v004_1.mp4',
  'S-AR5': 'static/create-space/h/AR_SAFESPEED_v004_1.mp4',
  'S-RH1': 'static/create-space/h/RH_GRADESEP_BARRIER_BARRIER_v004_1.mp4',
  'S-RH2': 'static/create-space/h/RH_GRADESEP_PAINTEDMEDIAN_BARRIER_v004_1.mp4',
  'S-RH3': 'static/create-space/h/RH_GRADESEP_TACTILE_BARRIER_v004_1.mp4',
  'S-RH4': 'static/create-space/h/RH_ROUNDABOUT_BARRIER_BARRIER_v004_1.mp4',
  'S-RH5': 'static/create-space/h/RH_ROUNDABOUT_PAINTEDMEDIAN_BARRIER_v004_1.mp4',
  'S-RH6': 'static/create-space/h/RH_ROUNDABOUT_TACTILE_BARRIER_v004_1.mp4',
  'S-RH7': 'static/create-space/h/RH_SAFESPEED_v004_1.mp4',
  'S-RH8': 'static/create-space/h/RH_SAFETYPLATFORM_BARRIER_BARRIER_v004_1.mp4',
  'S-RH9': 'static/create-space/h/RH_SAFETYPLATFORM_PAINTEDMEDIAN_BARRIER_v004_1.mp4',
  'S-RH10': 'static/create-space/h/RH_SAFETYPLATFORM_TACTILE_BARRIER_v004_1.mp4'
}

const I = {
  'Town Centre': {
    icon: 'static/create-space/ijk/intersections.png',
    copy: 'Intersections',
    video: 'static/create-space/ijk/TC_Accident_01_v004_1.mp4'
  },
  'Local Street': {
    icon: 'static/create-space/ijk/intersections.png',
    copy: 'Intersections',
    video: 'static/create-space/ijk/RS_Accident_01_v003_1.mp4'
  },
  'Arterial Road': {
    icon: 'static/create-space/ijk/AR-veer-right.png',
    copy: 'Veer Off-Road Right',
    video: 'static/create-space/ijk/AR_Accident_03_V2_1.mp4'
  },
  'Rural Highway': {
    icon: 'static/create-space/ijk/RH-veer-right.png',
    copy: 'Veer Off-Road Right',
    video: 'static/create-space/ijk/RH_Accident_01_v004_1.mp4'
  }
}

const J = {
  'Town Centre': {
    icon: 'static/create-space/ijk/pedestrians.png',
    copy: 'Pedestrians',
    video: 'static/create-space/ijk/TC_Accident_02_1.mp4'
  },
  'Local Street': {
    icon: 'static/create-space/ijk/pedestrians.png',
    copy: 'Pedestrians',
    video: 'static/create-space/ijk/RS_Accident_01_v003_1.mp4'
  },
  'Arterial Road': {
    icon: 'static/create-space/ijk/veer-left.png',
    copy: 'Veer Off-Road Left',
    video: 'static/create-space/ijk/AR_Accident_02_V21_1.mp4'
  },
  'Rural Highway': {
    icon: 'static/create-space/ijk/veer-left.png',
    copy: 'Veer Off-Road Left',
    video: 'static/create-space/ijk/RH_Accident_02_v004_1.mp4'
  }
}

const K = {
  'Town Centre': {
    icon: 'static/create-space/ijk/cyclists.png',
    copy: 'Cyclists',
    video: 'static/create-space/ijk/TC_Accident_03_1.mp4'
  },
  'Local Street': {
    icon: 'static/create-space/ijk/cyclists.png',
    copy: 'Cyclists',
    video: 'static/create-space/ijk/RS_Accident_02_v003_1.mp4'
  },
  'Arterial Road': {
    icon: 'static/create-space/ijk/intersections.png',
    copy: 'Intersections',
    video: 'static/create-space/ijk/AR_Accident_01_V2_1.mp4'
  },
  'Rural Highway': {
    icon: 'static/create-space/ijk/intersections.png',
    copy: 'Intersections',
    video: 'static/create-space/ijk/RH_Accident_03_v004_1.mp4'
  }
}

const LM = {
  'Town Centre': {
    10: {
      image: 'static/create-space/l/Speed-Limit-10-SHARED.png',
      copy: 'Some town centres around Victoria have a 10km/h shared space limit. This speed drastically reduces the likelihood of serious injury or death.'
    },
    40: {
      image: 'static/create-space/l/Speed-Limit-40.png',
      copy: 'Some town centres around Victoria have a 40km/h limit. The risk of serious injury or death is reduced at this speed.'
    },
    50: {
      image: 'static/create-space/l/Speed-Limit-50.png',
      copy: 'Some town centres around Victoria have a 50km/h limit. There is an increased chance of serious injury or death at this speed.'
    }
  },

  'Local Street': {
    10: {
      image: 'static/create-space/l/Speed-Limit-10-SHARED.png',
      copy: 'Some local streets around Victoria have a 10km/h shared space limit. This speed drastically reduces the likelihood of serious injury or death.'
    },
    40: {
      image: 'static/create-space/l/Speed-Limit-40.png',
      copy: 'Some local streets around Victoria have a 40km/h limit. The risk of serious injury or death is reduced at this speed.'
    },
    50: {
      image: 'static/create-space/l/Speed-Limit-50.png',
      copy: 'Some local streets around Victoria have a 50km/h limit. There is an increased chance of serious injury or death at this speed.'
    }
  },

  'Arterial Road': {
    50: {
      image: 'static/create-space/l/Speed-Limit-50.png',
      copy: 'Some arterial roads around Victoria have a 50km/h limit. This speed drastically reduces the likelihood of serious injury or death.'
    },
    60: {
      image: 'static/create-space/l/Speed-Limit-60.png',
      copy: 'Some arterial roads around Victoria have a 60km/h limit. The risk of serious injury or death is reduced at this speed.'
    },
    80: {
      image: 'static/create-space/l/Speed-Limit-80.png',
      copy: 'Many arterial roads around Victoria have a 80km/h limit. There is an increased chance of serious injury or death at this speed.'
    }
  },
  'Rural Highway': {
    50: {
      image: 'static/create-space/l/Speed-Limit-50.png',
      copy: 'Some rural highway around Victoria have a 50km/h limit. This speed drastically reduces the likelihood of serious injury or death.'
    },
    70: {
      image: 'static/create-space/l/Speed-Limit-70.png',
      copy: 'Some rural highways around Victoria have a 70km/h limit. The risk of serious injury or death is reduced at this speed.'
    },
    100: {
      image: 'static/create-space/l/Speed-Limit-100.png',
      copy: 'Many rural highways around Victoria have a 100km/h limit. There is an increased chance of serious injury or death at this speed.'
    }
  }
}

// N, O, P & Q
const saferSystems = {
  'TG-TC1': {
    name: 'Town Centre',
    image: 'static/create-space/n/TC_Treatment_Vehicle_FullyPhaseTrafficSignalsWith50kmSafetyPlatform_v004.png',
    video: 'static/create-space/o/TC_Treatment_Vehicle_FullyPhaseTrafficSignalsWith50kmSafetyPlatform_v004_1.mp4',
    title: 'Traffic Signals with 30km/h safety platform',
    copy: 'Traffic signals coordinate the flow of traffic in intersections and reduces speed.'
  },
  'TG-TC2': {
    name: 'Town Centre',
    image: 'static/create-space/n/TC_Treatment_Vehicle_RoundaboutWithWombatCrossings_v004.png',
    video: 'static/create-space/o/TC_Treatment_Vehicle_RoundaboutWithWombatCrossings_v004_1.mp4',
    title: 'Roundabout with wombat crossings',
    copy: 'A roundabout slows vehicles, reducing the risk of a serious crash occuring.'
  },
  'TG-TC3': {
    name: 'Town Centre',
    image: 'static/create-space/n/TC_Treatment_Pedestrian_WombatCrossingMidBlock_v004.png',
    video: 'static/create-space/o/TC_Treatment_Pedestrian_WombatCrossingMidBlock_v004_1.mp4',
    title: 'Wombat crossings mid-block',
    copy: 'Wombat crossings for pedestrians have raised safety platforms to slow traffic.'
  },
  'TG-TC4': {
    name: 'Town Centre',
    image: 'static/create-space/n/TC_Treatment_Pedestrian_FencingOfBarriersBetweenIntersections_V004.png',
    video: 'static/create-space/o/TC_Treatment_Pedestrian_FencingOfBarriersBetweenIntersections_V004_1.mp4',
    title: 'Fencing or barriers between intersections',
    copy: 'Used on the side of the road or the median to stop pedestrians crossing midblock.'
  },
  'TG-TC5': {
    name: 'Town Centre',
    image: 'static/create-space/n/TC_Treatment_Cyclists_SegregateBikePath_v004.png',
    video: 'static/create-space/o/TC_Treatment_Cyclists_SegregateBikePath_v004_1.mp4',
    title: 'Segregated cycle path',
    copy: 'Provides physical separation from vehicles on the road.'
  },
  'TG-TC6': {
    name: 'Town Centre',
    image: 'static/create-space/n/TC_SAFESPEED_V2_1.png',
    title: '10km/h Shared Zone',
    copy: 'Lowering the speed limit to 10km/h and creating a shared space for all road users results in a safe and appealing town centre.'
  },
  'TG-LS1': {
    name: 'Local Street',
    image: 'static/create-space/n/RS_Treatment_Vehicle_SafetyPlatform_v003.png',
    video: 'static/create-space/o/RS_Treatment_Vehicle_SafetyPlatform_v003_1.mp4',
    title: '30 km/h safety platforms',
    copy: 'Reduce vehicle approach speeds and travel speed through the intersection.'
  },
  'TG-LS2': {
    name: 'Local Street',
    image: 'static/create-space/n/RS_Treatment_Vehicle_RoundaboutWithWombatCrossings_v004.png',
    video: 'static/create-space/o/RS_Treatment_Vehicle_RoundaboutWithWombatCrossings_v004_1.mp4',
    title: 'Roundabout with wombat crossings',
    copy: 'A roundabout slows vehicles, reducing the risk of a serious crash occurring.'
  },
  'TG-LS3': {
    name: 'Local Street',
    image: 'static/create-space/n/RS_Treatment_Pedestrian_WombatCrossing_v003.png',
    video: 'static/create-space/o/RS_Treatment_Pedestrian_WombatCrossing_v003_1.mp4',
    title: 'Wombat crossings mid-block',
    copy: 'Wombat crossings for pedestrians have raised safety platforms to slow traffic.'
  },
  'TG-LS4': {
    name: 'Local Street',
    image: 'static/create-space/n/RS_Treatment_Cyclist_SegregatedBikePath_v003.png',
    video: 'static/create-space/o/RS_Treatment_Cyclist_SegregatedBikePath_v003_1.mp4',
    title: 'Segregated cycle path',
    copy: 'Provides physical separation from vehicles on the road.'
  },
  'TG-LS5': {
    name: 'Local Street',
    image: 'static/create-space/n/RS_SAFESPEED_V2_1.png',
    title: '10km/h Shared Zone',
    copy: 'Lowering the speed limit to 10km/h and creating a shared space for all road users results in a safe and appealing local street.'
  },
  'TG-AR1': {
    name: 'Arterial Road',
    image: 'static/create-space/n/AR_Treatment_Intersection_Roundabout_V2.png',
    video: 'static/create-space/o/AR_Treatment_Intersection_Roundabout_V2_1.mp4',
    title: 'Roundabout',
    copy: 'Slows vehicles, reducing the risk of a serious crash occurring.'
  },
  'TG-AR2': {
    name: 'Arterial Road',
    image: 'static/create-space/n/AR_Treatment_RoadLeft_FlexibleBarriersV2.png',
    video: 'static/create-space/o/AR_Treatment_RoadLeft_FlexibleBarriersV2_1.mp4',
    title: 'Continuous Flexible Side Barriers',
    copy: 'Stop vehicles running off the road and crashing into a tree, pole or embankment.'
  },
  'TG-AR3': {
    name: 'Arterial Road',
    image: 'static/create-space/n/AR_Treatment_RoadLeft_ShrubsV2.png',
    video: 'static/create-space/o/AR_Treatment_RoadLeft_ShrubsV2_1.mp4',
    title: 'Small Shrubs and energy absorbing poles',
    copy: 'Standard power poles and sign posts are replaced with energy-absorbing poles and posts that collapse or break away.'
  },
  'TG-AR4': {
    name: 'Arterial Road',
    image: 'static/create-space/n/AR_Treatment_RoadRight_FlexibleBarriers_V2.png',
    video: 'static/create-space/o/AR_Treatment_RoadRight_FlexibleBarriers_V2_1.mp4',
    title: 'Continuous Flexible Median Barriers',
    copy: 'Stop vehicles crashing into oncoming traffic or running of the road to the right.'
  },
  'TG-AR5': {
    name: 'Arterial Road',
    image: 'static/create-space/n/AR_Treatment_RoadRight_ShrubsV2.png',
    video: 'static/create-space/o/AR_Treatment_RoadRight_ShrubsV2_1.mp4',
    title: 'Small Shrubs and energy absorbing poles',
    copy: 'Standard power poles and sign posts are replaced with energy-absorbing poles and posts that collapse or break away.'
  },
  'TG-AR6': {
    name: 'Arterial Road',
    image: 'static/create-space/n/RH_SAFESPEED_v004.png',
    title: '50km/h Speed Limit',
    copy: 'Lowering the speed limit to 50km/h creates a safe road environment for all road users on arterial roads.'
  },
  'TG-RH1': {
    name: 'Rural Highway',
    image: 'static/create-space/n/RH_Treatment_Intersection_GradeSeparation_v004.png',
    video: 'static/create-space/o/RH_Treatment_Intersection_GradeSeparation_v004_1.mp4',
    title: 'Grade Seperation',
    copy: 'The most effective way to stop crashes between vehicles at an intersection.'
  },
  'TG-RH2': {
    name: 'Rural Highway',
    image: 'static/create-space/n/RH_Treatment_Intersection_Roundabout_v004.png',
    video: 'static/create-space/o/RH_Treatment_Intersection_Roundabout_v004_1.mp4',
    title: 'Roundabout',
    copy: 'Slows vehicles, reducing the risk of a serious crash occurring.'
  },
  'TG-RH3': {
    name: 'Rural Highway',
    image: 'static/create-space/n/RH_Treatment_Intersection_SafetyPlatform_v004.png',
    video: 'static/create-space/o/RH_Treatment_Intersection_SafetyPlatform_v004_1.mp4',
    title: 'Traffic Signals with 50km/h safety platform',
    copy: 'Reduce vehicle approach speeds and travel speed through the intersection.'
  },
  'TG-RH4': {
    name: 'Rural Highway',
    image: 'static/create-space/n/RH_Treatment_VehicleToLeft_FlexibleBarriers_v004.png',
    video: 'static/create-space/o/RH_Treatment_VehicleToLeft_FlexibleBarriers_v004_1.mp4',
    title: 'Continuous Flexible Side Barriers',
    copy: 'Stop vehicles running off the road and crashing into a tree, pole or embankment.'
  },
  'TG-RH5': {
    name: 'Rural Highway',
    image: 'static/create-space/n/RH_Treatment_VehicleToVehicle_FlexibleMidBarriers_v004.png',
    video: 'static/create-space/o/RH_Treatment_VehicleToVehicle_FlexibleMidBarriers_v004_1.mp4',
    title: 'Continuous Flexible Median Barriers',
    copy: 'Stop vehicles crashing into oncoming traffic or running of the road to the right.'
  },
  'TG-RH6': {
    name: 'Rural Highway',
    image: 'static/create-space/n/RH_Treatment_VehicleToVehicle_PaintedMedian_v004.png',
    video: 'static/create-space/o/RH_Treatment_VehicleToVehicle_PaintedMedian_v004_1.mp4',
    title: 'Painted Median',
    copy: 'Helps drivers to stay in their lane by providing a visual cue.'
  },
  'TG-RH7': {
    name: 'Rural Highway',
    image: 'static/create-space/n/RH_Treatment_VehicleToVehicle_TactileCenterline_v004.png',
    video: 'static/create-space/o/RH_Treatment_VehicleToVehicle_TactileCenterline_v004_1.mp4',
    title: 'Tactile Centrelines',
    copy: 'Makes noise upon contact with a vehicle to warn the driver they\'re moving into the oncoming lane.'
  },
  'TG-RH8': {
    name: 'Rural Highway',
    image: 'static/create-space/n/RH_SAFESPEED_v004.png',
    title: '50km/h Speed Limit',
    copy: 'Lowering the speed limit to 50km/h creates a safe road environment for all road users on rural highway.'
  }
}

export default function mapCreateSpaceContent (data) {
  const newData = Object.assign({}, data)

  if (!get(newData, 'data.createspace.physics.saferSystemsScenario.id')) return newData

  console.log('Mapping create space content…')

  if (newData.data.createspace !== null && newData.data.createspace.hasOwnProperty('physics')) {
    const p = newData.data.createspace.physics
    const scenarioId = p.saferSystemsScenario.id
    const scenarioName = p.saferSystemsScenario.name
      .replace(/^Town Center$/, 'Town Centre')
      .replace(/^Residential Street$/, 'Local Street')
      .replace(/^Rural Highway$/, 'Rural Highway')
      .replace(/^(School Zone|Main Road)$/, 'Arterial Road')
    const selectedSpeed = p.saferSystemsScenario.selectedSpeed
    const speeds = p.saferSystemsScenario.speeds || []

    const physicsData = {
      podName: A[p.podName] || A['A1'],
      scenario: {
        name: scenarioName.toUpperCase(),
        copy: C[scenarioName],
        video: H[scenarioId],
        risks: [
          I[scenarioName],
          J[scenarioName],
          K[scenarioName]
        ]
      },
      scoreboard: {
        // image: `uploads/${p.screenScoreboardId}`,
        image: p.screenScoreboardUrl || `/uploads/${p.screenScoreboardId}`,
        copy: G
      },

      graph: {
        // image: `uploads/${p.screenGraphId}`,
        image: p.screenGraphUrl || `/uploads/${p.screenGraphId}`,
        copy: E,
        csvUrl: p.csvUrl || ''
      },

      // screenExperimentId: `uploads/${p.screenExperimentId}`,
      screenExperimentUrl: p.screenExperimentUrl || `/uploads/${p.screenExperimentId}`,

      speed: LM[scenarioName] ? LM[scenarioName][selectedSpeed] : undefined
    }

    // inject data based on tratmentIdGroup[1..3]
    const treatments = []
    speeds.forEach(sdata => {
      if (sdata.hasOwnProperty('treatmentIdGroup1')) {
        const tid = sdata.treatmentIdGroup1
        if (tid && saferSystems[tid]) treatments.push(saferSystems[tid])
      }
    })

    physicsData.treatments = treatments

    // override physics object with mapped one.
    newData.data.createspace.physics = physicsData
  }

  return newData
}
