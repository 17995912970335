import React from 'react'

export default function Answer (props) {
  const { userText, correctText, avatarUrl } = props

  return (
    <div className='answer flex mt3'>
      <div className='answer-content fos fw4 tr pa4'>
        <p className='user-answer'>Your answer: {userText}</p>
        <p className='correct-answer mt2'>Correct answer: {correctText}</p>
      </div>
      <div className='avatar dn flex-ns'>
        <img src={avatarUrl} />
      </div>
    </div>
  )
}
