import React, { Component, Fragment } from 'react'
import './index.css'
import closeIcon from '../../assets/icons/close-media.svg'

export default class LightboxComponent extends Component {
  render () {
    return (
      <Fragment>
        <div
          onClick={() => this.props.openMedia()}
          className='lightbox fixed left-0 w-100 h-100 flex justify-center items-center'
        >
          <div
            width={this.props.width}
            height={this.props.height}
            className='lightbox-container relative'
          >
            {
              this.props.image
                ? React.cloneElement(this.props.image, {
                  className: `lightbox-item relative`,
                  width: `${this.props.width}px`,
                  height: `${this.props.height}px`
                }) : null
            }
            {!this.props.video &&
              <div className='media-overlay absolute flex justify-center items-center flex-column pointer'>
                <img className='mt4' src={closeIcon} />
                <h5 className='white mt3'>CLOSE</h5>
              </div>
            }

          </div>
        </div>
      </Fragment>
    )
  }
}
