import React, { Component } from 'react'
import Logo from '../../assets/logo-intro.svg'
import './index.css'

export default class Loading extends Component {
  static propTypes = {}

  render () {
    return (
      <div className='loading w-100 vh-100 flex flex-column items-center justify-center white'>
        <div className='mv4'><img className='footer-logo' src={Logo} /></div>
        <div className='pt4 pt5-l f1'>LOADING...</div>
      </div>
    )
  }
}
